import color from "color";
import { Bot } from "../../util/types";
import { getRandomBotParts } from "./parts";
import { getRandomBotSound } from "./sounds";

export function getRandomBotColors(prevColors?: { base: string }) {
  let baseColor;
  if (prevColors) {
    const prevHue = color(prevColors.base).hsl().object().h;
    let newHue;
    do {
      newHue = (prevHue + Math.random() * 180) % 360;
      var hueDifference = Math.abs(newHue - prevHue);
      hueDifference = hueDifference > 180 ? 360 - hueDifference : hueDifference;
    } while (hueDifference < 60); // Ensure the new hue is at least 60 degrees different
    baseColor = color.hsl(newHue, 70, 70);
  } else {
    baseColor = color.hsl(Math.random() * 360, 70, 70);
  }
  const base = baseColor;

  let baseShadow;
  // 20% chance to change the baseShadow color
  if (Math.random() < 0.2) {
    let shadowHue;
    do {
      shadowHue = (base.hsl().object().h + Math.random() * 180) % 360;
      var hueDifference = Math.abs(shadowHue - base.hsl().object().h);
      hueDifference = hueDifference > 180 ? 360 - hueDifference : hueDifference;
    } while (hueDifference < 30 || hueDifference > 180); // Ensure the new hue is between 30 and 90 degrees different
    const baseLightness = base.hsl().object().l;
    const shadowLightness = Math.max(baseLightness - 10, 50); // Ensure the shadow color is not too dark
    baseShadow = color.hsl(shadowHue, 50, shadowLightness);
  } else {
    baseShadow = base.darken(0.2);
  }

  let accent;
  do {
    accent = color.hsl(Math.random() * 360, 70, 70);
    var hueDifference = Math.abs(
      accent.hsl().object().h - base.hsl().object().h
    );
    hueDifference = hueDifference > 180 ? 360 - hueDifference : hueDifference;
  } while (hueDifference < 30); // Ensure the new hue is at least 60 degrees different

  const accentShadow = accent.darken(0.2);
  const outline = color("#1D1F21");
  const shine = color("#fff9e0");

  return {
    base: base.hex(),
    baseShadow: baseShadow.hex(),
    accent: accent.hex(),
    accentShadow: accentShadow.hex(),
    outline: outline.hex(),
    shine: shine.hex(),
  };
}

export function getRandomBot(prevBot?: Bot): Bot {
  return {
    parts: getRandomBotParts(),
    colors: getRandomBotColors(prevBot?.colors),
    sound: getRandomBotSound(),
  };
}
