import { BotBodyPartProps } from "../../BotSVG";

const Antenna1 = ({
  colors: { accent, accentShadow, outline, shine },
  outlineWeight,
}: BotBodyPartProps) => (
  <g id="antenna">
    <style>
      {`
        @keyframes glowing {
          0% { filter: drop-shadow( 0 0 2px ${accent} ); }
          40% { filter: drop-shadow( 0 0 5px ${accent} ); }
          50% { filter: drop-shadow( 0 0 20px ${accent} ); }
          60% { filter: drop-shadow( 0 0 5px ${accent} ); }
          100% { filter: drop-shadow( 0 0 2px ${accent} ); }
        }

        #glowingPath {
          animation: glowing ${Math.floor(Math.random() * 3) + 3}s infinite;
        }
      `}
    </style>
    <g>
      <path
        d="M258.37,440.3c12.9-28.23,63.54-193.12,63.54-193.12,0,0-38.94-28.57-39.69-31.07-1.92-6.39,62.42-27.82,61.93-31.88-1.06-8.89-50.93-59.22-53.36-66.28"
        style={{
          fill: "none",
          stroke: outline,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
      <path
        d="M281.3,53.33c-18.67,3.89-53.32,30.37-42.67,63.24,5.66,17.48,44.07,32.8,71.69,25.33,27.01-7.3,39.6-33.56,33.92-57.57,0,0-14.85-41.02-62.94-31Z"
        style={{
          fill: accent,
          strokeWidth: 0,
        }}
      />
      <path
        d="M237.52,98.67c3.71-8.45,54.44,56.55,105.49-6.66,6.18-7.65,1.45,47.69-54.03,53,0,.01-69.46-5.31-51.46-46.34Z"
        style={{
          fill: accentShadow,
          strokeWidth: 0,
        }}
      />
      <path
        id="glowingPath"
        d="M287.38,142.42c-15.24,0-30.05.22-42.17-14.82-11.21-13.92-10.85-36.14-1.16-51.46,8.42-13.32,35.04-23.33,50.98-23.33,23.77,0,40.15,7.27,45.2,20.92,8.81,23.78,4.33,39.39-8.94,56.26-9.7,10.54-34.1,12.5-43.91,12.43Z"
        style={{
          fill: "none",
          stroke: outline,
          strokeMiterlimit: 10,
          strokeWidth: outlineWeight,
        }}
      />
      <path
        d="M289,74.73c4.06-3.01,19.38-5.3,24.57-2.96,5.51,2.48,10.61,9.88-.73,14.84-4.08,1.78-20.52,8.72-25.43-.68-.07-.14-.15-.29-.21-.44,0,0-2.93-7.25,1.8-10.76Z"
        style={{
          fill: shine,
          strokeWidth: 0,
        }}
      />
    </g>
  </g>
);

export default Antenna1;
