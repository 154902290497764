import { SVGProps } from "react";

import { BotColors, BotParts } from "../../util/types";
import { renderBotParts } from "./parts";
import styled from "styled-components";

export interface BotBodyPartProps {
  colors: BotColors;
  outlineWeight: number;
}

export interface BotSVGProps extends SVGProps<SVGSVGElement> {
  colors: BotColors;
  outlineWeight?: number;
  parts: BotParts;
  height?: number | string;
}

const BotContainer = styled.div`
  position: relative;
  height: 95px;
  width: 58px;
  max-width: 100%;
  max-height: 100%;

  svg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .animation {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

export const BotSVG = ({
  colors,
  outlineWeight = 18,
  parts,
  height = 120,
}: BotSVGProps) => {
  const { Antenna, Eyes, Head, Neck, Body } = renderBotParts(parts);

  return (
    <BotContainer
      style={{
        height: height,
        width: (height as number) * (580 / 950),
      }}
    >
      <div className="filler"></div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 580 950">
        <Body colors={colors} outlineWeight={outlineWeight} />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 580 950">
        <Neck colors={colors} outlineWeight={outlineWeight} />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 580 950">
        <Antenna colors={colors} outlineWeight={outlineWeight} />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 580 950">
        <Head colors={colors} outlineWeight={outlineWeight} />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 580 950">
        <Eyes colors={colors} outlineWeight={outlineWeight} />
      </svg>
    </BotContainer>
  );
};
