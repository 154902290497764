import { useContext } from "react";
import { ScreenContainer } from "../../CurrentScreen";
import { MuteButton } from "../components/MuteButton";
import { RoomCode } from "./components/RoomCode";
import { RoomContext } from "../../App";

export const HostContainer = ({
  muted,
  toggleMute,
  roomCodeVisible,
  children,
}: {
  muted: boolean;
  toggleMute: () => void;
  roomCodeVisible: boolean;
  children: React.ReactNode;
}) => {
  const room = useContext(RoomContext);

  return (
    <ScreenContainer>
      <MuteButton muted={muted} toggleMute={toggleMute} />
      {roomCodeVisible && <RoomCode code={room?.id} />}
      {children}
    </ScreenContainer>
  );
};
