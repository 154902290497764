import { BotBodyPartProps } from "../../BotSVG";

const Head2 = ({
  colors: { base, outline, shine },
  outlineWeight,
}: BotBodyPartProps) => (
  <g id="head">
    <g>
      <path
        d="M482.54,318.18c-9.06-26.94-84.5-6.47-112.52-9.93-18.46-2.28-52.54-22.75-71.12-21.88-19.08.89-53.5,22.1-72.49,24.16-29.47,3.21-91.96-9.69-118.19,9.5-15.99,11.7-36.79,220.4,3.93,242.91,38.66,21.37,325.58,9.83,363.84-7.35-.01,0,27.94-173.79,6.55-237.41Z"
        style={{
          fill: base,
          strokeWidth: 0,
        }}
      />
      <path
        d="M139.2,356.51c-36.3,25.69-14.2,169.72-3.88,180.81,9.14,9.81,255.03,13.92,312.38-10.87,9.68-4.18,13.14-122.63,8.66-171.93-1.52-16.76-35.15-15.94-36.9-16.08,0-.01-231.17-16.68-280.26,18.07Z"
        style={{
          fill: "#79a7d9",
          strokeWidth: 0,
        }}
      />
      <polygon
        points="157.2 517.82 401.13 360.96 427.78 363.31 157.2 517.82"
        style={{
          fill: "none",
          stroke: shine,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
      <line
        x1={330.88}
        y1={360.98}
        x2={275.49}
        y2={393.74}
        style={{
          fill: "none",
          stroke: shine,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
      <path
        d="M124.77,419.52s-4.08-48.44,5.18-61.75c8.76-12.57,59.64-11.82,59.64-11.82"
        style={{
          fill: "none",
          stroke: outline,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
      <path
        d="M458.73,468.53s.4,45.02-9.35,56.53c-9.2,10.88-60.02,8.15-60.02,8.15"
        style={{
          fill: "none",
          stroke: outline,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
      <path
        d="M360.02,305.49c2.51,6.67,128.18-12.1,131,26.15,3.72,50.44-2.19,150.69-4.16,183.33-2.42,40.17-25.46,50.77-53.73,53.36-58.3,5.34-94.4,1.3-152.72,2.12-45.3.64-90.62.45-135.93.03-38.11-.36-47.05-8.45-52.35-46.29-2.41-17.16-9.18-136.58,1.39-189.57,6.61-33.12,133.91-19.47,140.59-26.16,23.8-23.78,94.97-24.47,125.91-2.97Z"
        style={{
          fill: "none",
          stroke: outline,
          strokeMiterlimit: 10,
          strokeWidth: outlineWeight,
        }}
      />
    </g>
  </g>
);

export default Head2;
