import { BotBodyPartProps } from "../../BotSVG";

const Body3 = ({
  colors: { accent, outline, shine },
  outlineWeight,
}: BotBodyPartProps) => (
  <g id="body">
    <path
      d="M83.64,894.57s-51.37-161.17-16.61-195.93c67.08-67.08,372.43-70.74,446.43-3.78,36.06,32.63-6.53,194.42-6.53,194.42l-423.29,5.29Z"
      style={{
        fill: accent,
        stroke: outline,
        strokeMiterlimit: 10,
        strokeWidth: outlineWeight,
      }}
    />
    <path
      d="M67.63,808.31c-13.2,22.62,1.47,108.69,1.47,108.69h445.37s10-84.04.09-103.82c-17.49-34.93-91.5-46.54-125.92-55.18-48.04-12.05-148.58-10.82-196.73.64-32.22,7.69-106.03,18.39-124.28,49.67Z"
      style={{
        fill: "#79a7d9",
        stroke: outline,
        strokeMiterlimit: 10,
        strokeWidth: outlineWeight,
      }}
    />
    <path
      d="M157.83,844.23c-17.9-43.55-28.84-125.75-30.43-174.44-.83-25.38,92.09-48.43,98.72-23.91,14.1,52.09,21.33,132.88,21.33,172.7,0,26.75,0,59.61-29.98,65.51-34.5,6.79-52.83-23.29-59.64-39.86Z"
      style={{
        fill: "#79a7d9",
        stroke: outline,
        strokeMiterlimit: 10,
        strokeWidth: outlineWeight,
      }}
    />
    <ellipse
      cx={209.46}
      cy={858.03}
      rx={42}
      ry={43.03}
      style={{
        fill: shine,
        stroke: outline,
        strokeMiterlimit: 10,
        strokeWidth: outlineWeight,
      }}
    />
    <path
      d="M441.02,844.23c17.9-43.55,28.84-125.75,30.43-174.44.83-25.38-92.09-48.43-98.72-23.91-14.1,52.09-21.33,132.88-21.33,172.7,0,26.75-.01,59.61,29.98,65.51,34.5,6.79,52.83-23.29,59.64-39.86Z"
      style={{
        fill: "#79a7d9",
        stroke: outline,
        strokeMiterlimit: 10,
        strokeWidth: outlineWeight,
      }}
    />
    <ellipse
      cx={393.39}
      cy={858.03}
      rx={42}
      ry={43.03}
      style={{
        fill: shine,
        stroke: outline,
        strokeMiterlimit: 10,
        strokeWidth: outlineWeight,
      }}
    />
  </g>
);

export default Body3;
