import { styled } from "styled-components";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import { useContext } from "react";
import { RoomContext, PlayerContext } from "../../App";
import { ScreenContainer } from "../../CurrentScreen";

export const Footer = styled(motion.div)`
  background: ${({ theme }) => theme.gradients.backdrop};
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  width: 100%;
`;

export const FooterRoomCode = styled(motion.div)`
  font-size: 1.25em;
  font-weight: bold;
  opacity: 0.5;
`;

export const FooterPlayerName = styled(motion.div)`
  font-size: 1.25em;
  font-weight: bold;
`;

export const PlayerContainer = ({
  footerVisible,
  children,
}: {
  footerVisible: boolean;
  children: React.ReactNode;
}) => {
  const room = useContext(RoomContext);
  const player = useContext(PlayerContext);

  return (
    <AnimatePresence>
      <ScreenContainer
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
      >
        {children}
        {footerVisible && (
          <Footer>
            <FooterRoomCode>{room?.id}</FooterRoomCode>
            <FooterPlayerName>{player?.name}</FooterPlayerName>
          </Footer>
        )}
      </ScreenContainer>
    </AnimatePresence>
  );
};
