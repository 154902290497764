import { BotBodyPartProps } from "../../BotSVG";

const Neck3 = ({
  colors: { base, accent, accentShadow, outline, shine },
  outlineWeight,
}: BotBodyPartProps) => (
  <g id="neck">
    <path
      d="M191.95,575.17c-16.25-9.77-54.2,39.1-29.02,65.55,39.9,41.9,181.75,42.69,246.55,4.45,34.27-20.22.67-82-25.53-70.78-45.42,19.43-150.34,25.82-192,.78Z"
      style={{
        fill: accentShadow,
        stroke: outline,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: outlineWeight,
      }}
    />
    <polygon
      points="255.03 684.12 268.81 563.74 319.99 567.89 342.55 675.12 255.03 684.12"
      style={{
        fill: outline,
        strokeWidth: 0,
      }}
    />
    <path
      d="M157.55,609.47c-6.75-3.55.56,57.37,5.38,68.7,21.36,50.23,218.7,43.28,247.55,3.95,11.38-15.51,18.97-75,9.61-71.24-109.24,43.79-167.45,48.61-262.54-1.41Z"
      style={{
        fill: accent,
        stroke: outline,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: outlineWeight,
      }}
    />
    <path
      d="M401.35,751.33s-28.99-19.81-28.22-35.8c1.74-36.33-30.35-48.76-54.25-39.6-6.86,2.63-16.58,10.39-19.52,36.28-.37,3.23-33.14,9.32-2.6,57.71,14.95,23.69,104.59-18.59,104.59-18.59Z"
      style={{
        fill: accent,
        stroke: outline,
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        strokeWidth: outlineWeight,
      }}
    />
    <path
      d="M183.69,781.34s.75-101.45,49.25-110.93c54.93-10.74,34.69,36.24,34.69,36.24,0,0,46.2.15,24,50.64-10.72,24.4-107.94,24.05-107.94,24.05Z"
      style={{
        fill: accent,
        stroke: outline,
        strokeLinecap: "square",
        strokeMiterlimit: 10,
        strokeWidth: outlineWeight,
      }}
    />
    <ellipse
      cx={287.45}
      cy={703.4}
      rx={36.76}
      ry={38}
      style={{
        fill: base,
        stroke: outline,
        strokeLinecap: "square",
        strokeMiterlimit: 10,
        strokeWidth: outlineWeight,
      }}
    />
    <path
      d="M271.28,709.64s-1.76-28.22,23.38-22.6"
      style={{
        fill: "none",
        stroke: shine,
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        strokeWidth: outlineWeight,
      }}
    />
  </g>
);

export default Neck3;
