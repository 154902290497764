import { BotBodyPartProps } from "../../BotSVG";

const Eyes3 = ({ colors: { outline } }: BotBodyPartProps) => (
  <g id="eyes">
    <path
      d="M229.39,420.1l-23.9-15.34s-8.03-31.14,38.29-38.34c24.69-3.84,28.17,22.81,27.95,51.36-.09,10.99,1.05,47.95-6.05,71.03-7.46,24.26-51.92,25.76-61.09,3.54-4.28-10.37-.68-65.11-.68-65.11l25.48-7.14Z"
      style={{
        fill: outline,
        strokeWidth: 0,
      }}
    />
    <path
      d="M357.59,420.1l23.9-15.34s8.03-31.14-38.29-38.34c-24.69-3.84-28.17,22.81-27.95,51.36.09,10.99-1.05,47.95,6.05,71.03,7.46,24.26,51.92,25.76,61.09,3.54,4.28-10.37.68-65.11.68-65.11l-25.48-7.14Z"
      style={{
        fill: outline,
        strokeWidth: 0,
      }}
    />
  </g>
);

export default Eyes3;
