import styled from "styled-components";
import { motion } from "framer-motion";
import { getTextColorForBackground } from "../../../util/colorUtils";

export const SpectrumLabel = styled(motion.p)`
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;

  &.most {
    color: ${(props) => props.theme.colors.error};
  }

  &.least {
    margin-top: 20px;
    color: ${(props) => props.theme.colors.success};
  }
`;

export const SpectrumName = styled(motion.h1)`
  font-size: 2.5rem;
  margin: 0;
  padding-bottom: 10px;
`;

export const RankedAnswerBot = styled(motion.div)`
  display: flex;
  position: absolute;
  right: 16px;
  bottom: 12px;
`;

export const RankedAnswersContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: flex-start;
  width: 80%;
  max-height: 90vh;
  text-align: center;
  align-items: center;
`;

export const RankedAnswerContainer = styled(motion.div)<{
  $isAI?: boolean;
  $variant?: "success" | "error";
  $hideText?: boolean;
}>`
  align-items: center;
  background: ${({ theme, $isAI }) =>
    $isAI ? theme.gradients.button.highlight : theme.gradients.backdrop};
  border-color: ${(props) =>
    props.$isAI
      ? props.theme.colors["highlight"]
      : props.$variant
      ? props.theme.colors[props.$variant]
      : "rgba(255, 255, 255, 0.2)"};
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  color: ${({ theme, $hideText }) =>
    theme.colors.text + ($hideText ? "77" : "")};
  display: flex;
  gap: 15px;
  justify-content: space-between;
  padding: 18px 10px 16px 10px;
  position: relative;
  transition: background 0.5s, border-color 0.5s, color 1s;
  width: 100%;
`;

export const RankedAnswerNumber = styled(motion.div)`
  display: flex;
  justify-content: center;
  text-align: right;
  width: 30px;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  opacity: 1;
  .rank {
    opacity: 0.3;
  }
`;

export const RankedAnswerText = styled.div`
  text-align: left;
  line-height: 1.5;
  overflow: hidden;
  border-radius: 10px;
  font-size: 1.2rem;
  margin: 0;
  width: 100%;

  &.no-answer {
    color: rgba(255, 255, 255, 0.5);
    font-style: italic;
  }
`;

export const RoundScore = styled(motion.div)<{
  $variant?: "success" | "error";
}>`
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: end;
  height: 50px;
  flex-direction: column;
  text-align: left;
  width: 50px;
  bottom: -15px;
  right: -42px;
  color: ${(props) =>
    props.$variant
      ? props.theme.colors[props.$variant]
      : props.theme.colors.highlight};
  background-color: transparent;
  font-size: 1rem;
  margin: 0;
`;

export const RankedScore = styled(motion.p)<{
  $variant?: "success" | "error";
}>`
  color: ${(props) =>
    props.$variant
      ? props.theme.colors[props.$variant]
      : props.theme.colors.highlight};
  font-size: 1.05rem;
  margin: 0;
`;

export const VoteScore = styled(motion.p)`
  color: #ffffffaa;
  font-size: 0.9rem;
  position: absolute;
  right: 10px;
  bottom: -12px;
  margin: 0;
`;

export const Badges = styled(motion.div)`
  display: flex;
  gap: 10px;
  position: absolute;
  right: 10px;
  bottom: 0;
  margin-bottom: -10px;
`;

export const VoteBadge = styled(motion.div)<{ $isAI?: boolean }>`
  background-color: #ffffff22;
  backdrop-filter: blur(5px);
  border-radius: 8px;
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
  color: #ffffffdd;
  font-size: 0.8rem;
  padding: 7px 10px 0px 10px;
  text-transform: uppercase;
`;

export const RankedAnswerAuthor = styled(motion.p)<{
  $variant?: "success" | "error";
  $playerColor: string;
}>`
  color: ${({ $variant, $playerColor }) =>
    $variant ? "white" : getTextColorForBackground($playerColor)};
  background-color: ${({ $variant, theme, $playerColor }) =>
    $variant ? theme.colors[$variant] : $playerColor};
  box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  border-radius: 10px;
  min-width: 40px;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  opacity: 0.8;
  transition: background-color 0.5s;
`;
