import styled from "styled-components";
import { motion } from "framer-motion";

const RoomCodeContainer = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0 10px 0 0;
  padding: 1rem;
  background: ${({ theme }) => theme.gradients.backdrop};

  span {
    opacity: 0.5;
    font-size: 1rem;
  }

  p {
    margin: 0;
    font-size: 1.25rem;
  }
`;

export const RoomCode = ({ code }: { code: string }) => {
  return (
    <RoomCodeContainer
      animate={{
        opacity: 1,
      }}
      initial={{
        opacity: 0,
      }}
      transition={{
        duration: 1,
        delay: 5,
      }}
    >
      <span>Room Code</span>
      <p>{code}</p>
    </RoomCodeContainer>
  );
};
