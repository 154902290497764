import { AnimatePresence } from "framer-motion";
import { useContext, useEffect, useState } from "react";

import { AI_ID } from "../../App";
import { PromptContainer, PromptLabel } from "./components/AnsweringPrompt";
import {
  SpectrumName,
  SpectrumLabel,
  RankedAnswersContainer,
} from "./components/AIRanking";
import { useAudio } from "../../util/useAudio";
import { SocketContext, RoomContext, PlayersContext } from "../../App";

import TransitionTheme from "../../assets/sounds/events/transition2.mp3";
import VictoryTheme from "../../assets/sounds/events/victory.mp3";
import DefeatTheme from "../../assets/sounds/events/defeat.mp3";
import { RankedAnswer } from "./components/RankedAnswer";

const DELAY_AFTER_REVEAL = 2000;

const timeout = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const HostAIRanking = () => {
  const socket = useContext(SocketContext);
  const room = useContext(RoomContext);
  const players = useContext(PlayersContext);

  if (!socket || !room || !players) throw new Error("No socket or room!");

  const AIRank = room.ranks[AI_ID];

  const [revealAI, setRevealAI] = useState(false);
  const [revealPlayers, setRevealPlayers] = useState(false);
  const [revealScores, setRevealScores] = useState(false);
  const [revealVotes, setRevealVotes] = useState(false);

  const [playTransitionTheme, pauseTransitionTheme] = useAudio(TransitionTheme);
  const [playVictoryTheme, pauseVictoryTheme] = useAudio(VictoryTheme);
  const [playDefeatTheme, pauseDefeatTheme] = useAudio(DefeatTheme);

  // Make sure this setTimeout only happens once
  useEffect(() => {
    playTransitionTheme();

    async function revealSequence() {
      const playerCount = Object.keys(players).length;
      // Reveal the AI Player
      await timeout(2000 + 1500 * playerCount + DELAY_AFTER_REVEAL);
      setRevealAI(true);
      // Play the victory or defeat theme, depending on if AI ranked first or not
      if (AIRank === 1) {
        playDefeatTheme();
      } else {
        playVictoryTheme();
      }
      // Reveal the other players
      await timeout(DELAY_AFTER_REVEAL);
      setRevealPlayers(true);
      // Reveal the scores
      await timeout(1500 * playerCount);
      setRevealScores(true);
      // Reveal the votes
      await timeout(750 * playerCount);
      setRevealVotes(true);
      // Wait a bit before moving on
      await timeout(7500);
      socket?.emit("roundComplete", { roomId: room.id });
    }
    revealSequence();

    return () => {
      pauseTransitionTheme();
      pauseVictoryTheme();
      pauseDefeatTheme();
    };
  }, []);

  return (
    <PromptContainer>
      <PromptLabel
        key="prompt"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: revealAI ? 0.75 : 0.5, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ delay: 0.5 }}
      >
        {revealScores
          ? "Here's how everyone scored!"
          : revealPlayers
          ? "Let's see who wrote the other answers..."
          : revealAI
          ? "First, let's see who the AI was..."
          : "Let's see if you tricked the AI..."}
      </PromptLabel>
      <AnimatePresence>
        {!revealAI && (
          <SpectrumName
            key="spectrum"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ delay: 1 }}
          >
            "Human" Detection Rankings
          </SpectrumName>
        )}
      </AnimatePresence>
      {!revealAI && (
        <SpectrumLabel
          key="most"
          animate={{ opacity: revealAI ? 0.5 : 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 1.5 }}
          className="least"
        >
          Least Human / Most AI
        </SpectrumLabel>
      )}
      <RankedAnswersContainer>
        {Object.entries(room.ranks)
          .sort((a, b) => Number(a[1]) - Number(b[1]))
          .map(([playerId, rank], index) => {
            const player = players[playerId];
            const isAI = playerId === AI_ID;
            if (!player) return null;
            return (
              <RankedAnswer
                playerId={playerId}
                rank={rank}
                index={index}
                isAI={isAI}
                revealAI={revealAI}
                revealPlayers={revealPlayers}
                revealScores={revealScores}
                revealVotes={revealVotes}
                AIRank={AIRank}
                player={player}
                room={room}
              />
            );
          })}
      </RankedAnswersContainer>
      {!revealAI && (
        <SpectrumLabel
          animate={{ opacity: revealAI ? 0.5 : 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 1.5 }}
          className="most"
        >
          Most Human / Least AI
        </SpectrumLabel>
      )}
    </PromptContainer>
  );
};
