import { keyframes, styled } from "styled-components";
import logo from "../../assets/splash_logo.png";
import { motion } from "framer-motion";

const shine = keyframes`
  0% { opacity: 0.3; }
  20% { opacity: 0.3; }
  50% { opacity: 0.6; }
  80% { opacity: 0.3; }
  100% { opacity: 0.3; }
`;

export const Logo = styled(motion.img)`
  width: 400px;
  max-width: 60%;
  animation: ${shine} 4s ease-in-out infinite;
  // Make it desaturated
  filter: grayscale(50%);
`;

export const Splash = () => <Logo src={logo} alt="Logo" />;
