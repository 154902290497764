import { AnimatePresence } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import Confetti from "react-confetti";
import { AiFillTrophy, AiFillStar, AiOutlineStar } from "react-icons/ai";
import useWindowSize from "react-use/lib/useWindowSize";

import { AI_ID } from "../../App";
import { Button } from "../components/Button";
import { PlayersContext, SocketContext } from "../../App";

import FinaleTheme from "../../assets/sounds/events/finale.mp3";
import {
  FinaleContainer,
  OuterContainer,
  ScoreContainer,
  Title,
  Score,
  ScoreName,
  ScoreIcon,
  ScoreNumber,
  ButtonContainer,
} from "./components/Finale";
import { useAudio } from "../../util/useAudio";
import { LoadingBar } from "../components/LoadingBar";

const getIcon = (place: number) => {
  switch (place) {
    case 1:
      return <AiFillTrophy color="gold" />;
    case 2:
      return <AiFillStar color="silver" />;
    case 3:
      return <AiFillStar color="#CD7F32" />;
    default:
      return <AiOutlineStar color="gray" />;
  }
};

export const HostFinale = () => {
  const { width, height } = useWindowSize();

  const socket = useContext(SocketContext);
  const players = useContext(PlayersContext);
  const [runConfetti, setRunConfetti] = useState(false);

  if (!players) throw new Error("No players!");

  const [playFinaleTheme, pauseFinaleTheme] = useAudio(FinaleTheme);

  const disconnect = () => {
    if (socket && socket.connected) socket.disconnect();
  };

  useEffect(() => {
    playFinaleTheme();
    setTimeout(() => setRunConfetti(true), 2000);

    return () => {
      pauseFinaleTheme();
    };
  }, []);

  // Sort players by score, filtering out the AI
  const sortedPlayers = Object.entries(players)
    .filter(([id]) => id !== AI_ID)
    .sort(([, a], [, b]) => b.score - a.score)
    .map(([, player]) => player);

  return (
    <AnimatePresence>
      <FinaleContainer
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2, delay: 2 }}
      >
        <OuterContainer>
          <ScoreContainer
            key="scores"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ staggerChildren: 0.5, delayChildren: 0.5, delay: 2 }}
          >
            <Confetti
              width={width}
              height={height}
              recycle={false}
              tweenDuration={20000}
              numberOfPieces={500}
              run={runConfetti}
              confettiSource={{
                x: 0,
                y: 0,
                w: width / 3,
                h: 0,
              }}
              wind={0.03}
            />
            <Title
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              Final Rankings
            </Title>
            {sortedPlayers.map((player, index) => (
              <Score
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{
                  delay: 2.5 + 0.5 * index,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <ScoreName>
                  <ScoreIcon>{getIcon(index + 1)}</ScoreIcon>
                  {player.name}
                </ScoreName>
                <ScoreNumber>{player.score || 0}</ScoreNumber>
              </Score>
            ))}
          </ScoreContainer>
        </OuterContainer>
        <ButtonContainer>
          <Button
            onClick={() => window.location.assign("/")}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 10 + 0.5 * sortedPlayers.length }}
          >
            Back to Lobby
          </Button>
        </ButtonContainer>
        <LoadingBar
          duration={20}
          delay={11 + 0.5 * sortedPlayers.length}
          onComplete={disconnect}
        />
      </FinaleContainer>
    </AnimatePresence>
  );
};
