import styled from "styled-components";
import { motion } from "framer-motion";

export const PromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 90vh;
  width: 90%;
  height: 80%;
  max-width: 900px;
  max-height: 600px;
`;

export const PromptLabel = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
  opacity: 0.5;
  margin: 0;
  padding-bottom: 0.75rem;
  text-align: left;
`;

export const Prompt = styled(motion.h1)`
  font-size: 2rem;
  margin: 0;
  text-align: center;
  padding-bottom: 1rem;
`;

export const PromptImage = styled(motion.img)`
  max-width: 60svw;
  max-height: 40svh;
  border-radius: 1rem;
  object-fit: contain;
  margin-bottom: 2rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
`;

export const PromptText = styled(motion.div)`
  font-size: 2.5rem;
  font-style: italic;
  margin: 0;
  text-align: center;
  margin-bottom: 2rem;
`;

export const PlayerStatuses = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1rem;
  justify-content: center;
  padding-top: 1rem;
  width: 100%;
`;

export const PlayerStatusContainer = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  max-width: 240px;
  margin: 0 25px;
`;

export const PlayerStatusName = styled(motion.p)`
  display: flex;
  gap: 0.75rem;
  font-size: 1.75rem;
  text-transform: uppercase;
  margin: 0;
`;

export const PlayerStatus = styled(motion.div)`
  margin-top: 5px;
  margin-left: -10px;
`;

export const WordLimit = styled(motion.div)`
  font-size: 1.2rem;
  text-align: center;
  opacity: 0.75;
  margin-bottom: 2rem;
  margin-top: -0.5rem;

  span {
    font-size: 1.05rem;
    font-weight: bold;
    opacity: 0.5;
  }
`;
