import { BotBodyPartProps } from "../../BotSVG";

const Eyes1 = ({ colors: { outline } }: BotBodyPartProps) => (
  <g id="eyes">
    <path
      d="M206.62,501.8c-20.55-1.37-32.78-12.4-40.42-29.76-18.35-41.67,7.25-96.77,40.42-108.99,30.95-11.4,64.54,1.45,70.58,37.74,4.26,25.58,1.31,49-13.67,71.08-13.58,20.01-30.18,32.34-56.91,29.93Z"
      style={{
        fill: outline,
        strokeWidth: 0,
      }}
    />
    <path
      d="M373.95,501.69c21.4-1.37,34.14-12.4,42.11-29.76,19.12-41.67-7.55-96.77-42.1-108.99-32.24-11.4-67.23,1.45-73.52,37.74-4.44,25.58-1.37,49,14.24,71.08,14.13,20,31.43,32.33,59.27,29.93Z"
      style={{
        fill: outline,
        strokeWidth: 0,
      }}
    />
  </g>
);

export default Eyes1;
