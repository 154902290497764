import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { FaMobileAlt } from "react-icons/fa";
import { TypeAnimation } from "react-type-animation";
import styled from "styled-components";

import { PromptContainer, PromptLabel } from "./components/AnsweringPrompt";
import { Timer } from "../components/Timer";
import { RoomContext, SocketContext } from "../../App";
import { PlayerID } from "../../util/types";
import { log } from "../../util/log";

import TransitionTheme from "../../assets/sounds/events/transition1.mp3";
import AnsweringTheme from "../../assets/sounds/theme/questions.mp3";
import PlayerVotedSound1 from "../../assets/sounds/answers/1.mp3";
import PlayerVotedSound2 from "../../assets/sounds/answers/2.mp3";
import PlayerVotedSound3 from "../../assets/sounds/answers/3.mp3";
import PlayerVotedSound4 from "../../assets/sounds/answers/4.mp3";
import PlayerVotedSound5 from "../../assets/sounds/answers/5.mp3";
import PlayerVotedSound6 from "../../assets/sounds/answers/6.mp3";
import { useAudio } from "../../util/useAudio";

const TYPING_SPEED = 50;

const VotingScreenPrompt = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1.5rem;

  &:has(img) {
    flex-direction: row;
    text-align: left;
  }

  span {
    font-size: 1.5rem;
    font-weight: normal;
    font-style: italic;
    opacity: 0.75;
  }
`;

const ImageThumbnail = styled(motion.img)`
  object-fit: cover;
  width: 100%;
  max-height: 150px;
  max-width: 200px;
  border-radius: 10px;
`;

const PlayerAnswerContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  align-items: flex-start;
  width: 80%;
`;

const PlayerAnswer = styled(motion.div)`
  position: relative;
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
`;

const PlayerAnswerNumber = styled.p`
  text-align: right;
  min-width: 40px;
  font-size: 2.5rem;
  font-weight: bold;
  opacity: 0.3;
  margin: 0;
`;

const PlayerAnswerText = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  line-height: 1.5;
  font-size: 1.25rem;
  padding: 15px;
  margin: 0;

  span {
    display: inline-block;

    &.no-answer {
      color: rgba(255, 255, 255, 0.5);
      font-style: italic;
    }
  }
`;

const PlayerAnswerVote = styled(motion.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.text};
  right: 0;
  top: 0;
  background: ${({ theme }) => theme.gradients.button.highlight};
  backdrop-filter: blur(5px);
  border: ${({ theme }) => theme.border.button.highlight};
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.glow.button.highlight};
  font-size: 1.5rem;
  font-weight: 400;
  padding: 5px;
  margin: 0;
  margin-right: -15px;
  margin-top: -15px;
  width: 20px;
  height: 20px;
`;

interface PlayerAnswerProps {
  playerId: string;
  answer: string;
  index: number;
  votes: number;
  onFinishedTyping?: () => void;
  resetOpacity?: boolean;
}

interface PlayerProps {
  from: PlayerID;
  to: PlayerID;
}

export const PlayerAnswerAnimation = ({
  playerId,
  answer,
  index,
  votes,
  onFinishedTyping,
  resetOpacity = false,
}: PlayerAnswerProps) => {
  const controls = useAnimation();
  const [hasAnimated, setHasAnimated] = useState(false);

  let animateResolve;
  const animatePromise = new Promise(function (resolve) {
    animateResolve = resolve;
  });

  useEffect(() => {
    const sequence = async () => {
      if (hasAnimated) return;
      await controls.start({ opacity: 0.8, y: 0 });
      await animatePromise;
      await controls.start({ scale: 1, opacity: 0.25 });
      onFinishedTyping?.();
      setHasAnimated(true);
    };

    sequence();
  }, [controls, answer, onFinishedTyping, hasAnimated]);

  useEffect(() => {
    if (resetOpacity) {
      controls.start({ opacity: 1 });
    }
  }, [resetOpacity, controls]);

  return (
    <PlayerAnswer
      key={playerId}
      initial={{ scale: 1.25, opacity: 0, y: 20 }}
      animate={controls}
    >
      <PlayerAnswerNumber>{index + 1}</PlayerAnswerNumber>
      <PlayerAnswerText>
        <TypeAnimation
          sequence={[
            index == 0 ? 1500 : 0,
            answer.length === 0 ? "No answer" : answer,
            answer.length === 0 ? 0 : 750,
            animateResolve ? animateResolve : "",
          ]}
          wrapper="span"
          style={{ display: "inline-block" }}
          className={answer.length === 0 ? "no-answer" : ""}
          cursor={false}
          speed={TYPING_SPEED}
        />
      </PlayerAnswerText>
      <AnimatePresence>
        {votes > 0 && (
          <PlayerAnswerVote
            initial={{ scale: 0, opacity: 0 }}
            animate={{
              scale: [0, 1 + 0.2 * (votes - 1), 1 + 0.1 * (votes - 1)],
              opacity: 1,
            }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ duration: 0.25 }}
          >
            {votes}
          </PlayerAnswerVote>
        )}
      </AnimatePresence>
    </PlayerAnswer>
  );
};

export const HostPlayerVoting = () => {
  const socket = useContext(SocketContext);
  const room = useContext(RoomContext);

  const [votes, setVotes] = useState<Record<PlayerID, PlayerID>>({});
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState(0);
  const [allAnimationsFinished, setAllAnimationsFinished] = useState(false);

  const controls = useAnimation();
  const { prompt, answers } = room;

  if (!socket || !prompt) {
    throw new Error("Socket or prompt not found");
  }

  const [playTransitionTheme, pauseTransitionTheme] = useAudio(
    TransitionTheme,
    {
      volume: 0.65,
    }
  );

  const [playAnsweringTheme, pauseAnsweringTheme] = useAudio(AnsweringTheme, {
    loop: true,
  });

  const playerVotedSounds = [
    PlayerVotedSound1,
    PlayerVotedSound2,
    PlayerVotedSound3,
    PlayerVotedSound4,
    PlayerVotedSound5,
    PlayerVotedSound6,
  ];

  useEffect(() => {
    playTransitionTheme();

    return () => {
      pauseTransitionTheme();
      pauseAnsweringTheme();
    };
  }, []);

  socket.on("playerAddedVote", ({ from, to }: PlayerProps) => {
    setVotes((prevVotes) => ({
      ...prevVotes,
      [from]: to,
    }));
  });

  socket.on("playerRemovedVote", ({ from, to }: PlayerProps) => {
    setVotes((prevVotes) => {
      const newVotes = { ...prevVotes };
      delete newVotes[from];
      return newVotes;
    });
  });

  const handleFinishedTyping = () => {
    setCurrentAnswerIndex((currentIndex) => {
      if (currentIndex + 1 === Object.keys(answers).length) {
        controls.start({ opacity: 1 });
        setAllAnimationsFinished(true);
        playAnsweringTheme();
        socket.emit("playerAnswersDisplayed", { roomId: room.id });
      }
      return currentIndex + 1;
    });
  };

  return (
    <PromptContainer>
      {Object.entries(answers).length === 0 ? (
        <PromptLabel
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 0.5, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          No player answers received, skipping round...
        </PromptLabel>
      ) : (
        <>
          <Timer />
          <PromptLabel
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 0.5, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            Here's what everyone responded to the prompt
          </PromptLabel>
          <VotingScreenPrompt
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1 }}
          >
            {prompt.type == "image" && <ImageThumbnail src={prompt.url} />}
            {prompt.prompt}
            {prompt.type == "rewrite" && <span>{prompt.text}</span>}
          </VotingScreenPrompt>
          <PlayerAnswerContainer
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.5 }}
          >
            {Object.entries(answers)
              .slice(0, currentAnswerIndex + 1)
              .map(([playerId, answer], index) => (
                <PlayerAnswerAnimation
                  key={playerId}
                  playerId={playerId}
                  answer={answer}
                  index={index}
                  votes={
                    Object.keys(votes).filter(
                      (from) => votes[from] === playerId
                    ).length
                  }
                  onFinishedTyping={handleFinishedTyping}
                  resetOpacity={allAnimationsFinished}
                />
              ))}
          </PlayerAnswerContainer>
          <PromptLabel
            initial={{ opacity: 0, y: 20 }}
            animate={allAnimationsFinished && { opacity: 0.5, y: 0 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            <FaMobileAlt size={25} />
            Vote on your device for which answer you think was written by AI
          </PromptLabel>
        </>
      )}
    </PromptContainer>
  );
};
