import styled from "styled-components";
import { motion } from "framer-motion";
import QRCode from "react-qr-code";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 40px;
  width: 90%;
  max-height: 90vh;

  .tip {
    font-size: 1em;
    text-align: center;
    color: #ffffff99;
    margin: 0;

    span {
      color: white;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const RoomCodeContainer = styled.div`
  flex: 0.8;
  margin: 0;
  min-width: 300px;
  max-width: 500px;
  background: ${({ theme }) => theme.gradients.backdrop};
  padding: 10px 15px;
  border-radius: 10px;

  p {
    margin: 0;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }

  .qr-code-container {
    display: none;
  }

  @media (min-width: 600px) {
    padding: 20px;

    .qr-code-container {
      display: block;
      margin: 25px auto 0 auto;
      padding: 8px;
      border-radius: 5px;
      background-color: white;
      width: 125px;
      max-width: 100%;

      @media (min-width: 768px) {
        width: 200px;
      }
    }

    .qr-code {
      height: auto;
      width: 100%;
    }
  }
`;

export const PlayerListContainer = styled(motion.div)`
  display: flex;
  flex: 1;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-width: 300px;
  max-width: 800px;

  .title {
    text-align: center;
    padding: 15px 0;
    margin: 0;
    font-size: 1.2em;
  }
  margin-bottom: 1.5rem;

  button {
    max-width: 500px;
  }
`;

export const PlayerList = styled(motion.div)`
  margin-top: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem 2.25rem;
  margin-bottom: 2rem;
`;

export const RoomCodeText = styled(motion.p)`
  font-size: 1.2em;
  opacity: 0.5;
  margin: 0;
`;

export const RoomCode = styled(motion.p)`
  font-size: 2em;
  font-weight: bold;
  background-color: #ffffff22;
  padding: 6px 10px;
  border-radius: 8px;
  margin: 0;
`;

export const QRCodeComponent = (roomId: string) => (
  <QRCode
    className="qr-code"
    value={new URL(`/?room=${roomId}`, window.location.href).href}
  />
);
