import { BotBodyPartProps } from "../../BotSVG";

const Antenna2 = ({
  colors: { base, accent, accentShadow, outline },
  outlineWeight,
}: BotBodyPartProps) => (
  <g id="antenna">
    <g>
      <path
        d="M374.39,133.27c-24.07,28.54-128.59,81.29-137.25,112.85-7.4,26.99,141.94,109.58,187.11,55.45,20.67-24.77-28.18-130.07-.25-158,10.54-10.54,20.33-41.72,15.43-50.66-2.92-5.34-49.96-2.58-66.88,1.69.01-.01,8.08,31.27,1.84,38.67Z"
        style={{
          fill: base,
          strokeWidth: 0,
        }}
      />
      <path
        d="M399.4,315.98c-40.74,2-109.84-2.47-165.09-64.92-12.36-13.97,67.35-12.16,98.13-5.52,35.73,7.7,85.44,26.34,88.68,46.33,0,0-14.8,23.77-21.72,24.11Z"
        style={{
          fill: accentShadow,
          strokeWidth: 0,
        }}
      />
      <path
        d="M417.76,202.43c-6.46-27.91-38.43-47.14-70.21-42.01-5.37.87,8.68-3.28,20.21-11.99,34.21-25.85,13.73,4.08,26.97-9.15.23-.23-1.35,5.22-2.27,10.84-2.42,14.79-3.91,21.12,5.23,4.63,1.81-3.27,5.11-7.76,8.96-14.72,2.02-3.64,1.87,21.92,4.93.22.47-3.35,7.15,14.53,7.7,16.92,0,0-.32,50.43-1.52,45.26Z"
        style={{
          fill: accent,
          strokeWidth: 0,
        }}
      />
      <path
        d="M421.12,266.69c-14.03-42.59-57.95-73.36-124.27-67.1-5.1.48,20.32-28.78,32.94-27.92,53.78,3.7,74.74,19.01,93.11,60,3.1,6.94,1.8,45.88-1.78,35.02Z"
        style={{
          fill: accent,
          strokeWidth: 0,
        }}
      />
      <path
        d="M395.43,307.62c26.52-26.52-185.11-88.74-134.17-37.8.58.58-56.2-24.2,1.42-44.01,44.91-15.44,159.37,42.61,164,67.5.19,1.02-32.1,15.16-31.25,14.31Z"
        style={{
          fill: accent,
          strokeWidth: 0,
        }}
      />
      <path
        d="M425.74,284.34c0,26.84-21.77,27.62-30.21,29.28-53.59,10.51-123.09-15.37-153.28-52.38-8.77-10.75-8.97-18.82,1.7-26.98,43.35-33.12,89.12-62.96,132.73-98.74-36.58-2.62-26.39-5.99,6.8-13.89-53.86-86.1,7.12-16.43,14.73-17.87,1.63-9.34,19.09-130.89,13.2-.19,84.07-62.25,9.5,4.09,22.1,21.61,24.17,33.6-11.57.44-15.39,30.79-4.83,38.35,7.62,125.83,7.62,128.37Z"
        style={{
          fill: "none",
          stroke: outline,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
    </g>
  </g>
);

export default Antenna2;
