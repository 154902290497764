import { useEffect, useRef, useCallback, useContext } from "react";
import { MutedContext } from "../App";

import AnsweringTheme from "../assets/sounds/theme/questions.mp3";
import LobbyTheme from "../assets/sounds/theme/lobby.mp3";
import DefeatTheme from "../assets/sounds/events/defeat.mp3";
import VictoryTheme from "../assets/sounds/events/victory.mp3";
import TransitionTheme1 from "../assets/sounds/events/transition1.mp3";
import TransitionTheme2 from "../assets/sounds/events/transition2.mp3";
import PlayerVotedSound1 from "../assets/sounds/answers/1.mp3";
import PlayerVotedSound2 from "../assets/sounds/answers/2.mp3";
import PlayerVotedSound3 from "../assets/sounds/answers/3.mp3";
import PlayerVotedSound4 from "../assets/sounds/answers/4.mp3";
import PlayerVotedSound5 from "../assets/sounds/answers/5.mp3";
import PlayerVotedSound6 from "../assets/sounds/answers/6.mp3";
import PlayerJoinSound1 from "../assets/sounds/join/1.mp3";
import PlayerJoinSound2 from "../assets/sounds/join/2.mp3";
import PlayerJoinSound3 from "../assets/sounds/join/3.mp3";
import PlayerJoinSound4 from "../assets/sounds/join/4.mp3";
import PlayerJoinSound5 from "../assets/sounds/join/5.mp3";
import PlayerJoinSound6 from "../assets/sounds/join/6.mp3";
import PlayerJoinSound7 from "../assets/sounds/join/7.mp3";

export const ALL_AUDIO_FILES = [
  AnsweringTheme,
  LobbyTheme,
  DefeatTheme,
  VictoryTheme,
  TransitionTheme1,
  TransitionTheme2,
  PlayerVotedSound1,
  PlayerVotedSound2,
  PlayerVotedSound3,
  PlayerVotedSound4,
  PlayerVotedSound5,
  PlayerVotedSound6,
  PlayerJoinSound1,
  PlayerJoinSound2,
  PlayerJoinSound3,
  PlayerJoinSound4,
  PlayerJoinSound5,
  PlayerJoinSound6,
  PlayerJoinSound7,
];

type AudioOptions = {
  loop?: boolean;
  volume?: number;
  onEnded?: () => void;
};

export const preloadAudio = () => {
  ALL_AUDIO_FILES.forEach((audioFile) => {
    const audio = new Audio(audioFile);
    audio.load();
  });
};

export const useAudio = (
  url: string,
  { loop = false, volume = 0.5, onEnded = () => {} }: AudioOptions = {}
) => {
  const muted = useContext(MutedContext);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const handleEndedRef = useRef(onEnded);
  const wasPlayingRef = useRef(false);

  // Initialize the audio element only once
  useEffect(() => {
    audioRef.current = new Audio(url);
    audioRef.current.loop = loop;
    audioRef.current.volume = volume;
    handleEndedRef.current = onEnded;

    const handleEnded = () => {
      handleEndedRef.current();
    };

    if (audioRef.current) {
      audioRef.current.addEventListener("ended", handleEnded);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("ended", handleEnded);
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [url]);

  // Update the onEnded callback
  useEffect(() => {
    handleEndedRef.current = onEnded;
  }, [onEnded]);

  // Handle changes to loop
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.loop = loop;
    }
  }, [loop]);

  // Handle changes to volume
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  // Handle changes to muted
  useEffect(() => {
    if (audioRef.current) {
      if (muted) {
        wasPlayingRef.current = !audioRef.current.paused;
        audioRef.current.muted = true;
        audioRef.current.pause();
      } else {
        audioRef.current.muted = false;
        if (wasPlayingRef.current) {
          audioRef.current.play();
        }
      }
    }
  }, [muted]);

  const play = useCallback(() => {
    if (audioRef.current) {
      wasPlayingRef.current = true;
      audioRef.current.play();
    }
  }, []);

  const pause = useCallback(() => {
    if (audioRef.current) {
      wasPlayingRef.current = false;
      audioRef.current.pause();
    }
  }, []);

  return [play, pause];
};

export const useOneOffAudio = (
  sounds: string[],
  volume: number = 0.65
): [(index: number) => void, () => void] => {
  const muted = useContext(MutedContext);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const play = useCallback(
    async (index: number) => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current.src = sounds[index];
      } else {
        audioRef.current = new Audio(sounds[index]);
      }

      audioRef.current.volume = volume;
      audioRef.current.muted = muted;

      try {
        await audioRef.current.play();
      } catch (error) {
        console.error("Playback failed:", error);
      }
    },
    [sounds, volume, muted]
  );

  const pause = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  }, []);

  return [play, pause];
};
