import { ReactElement } from "react";

import { BotBodyPartProps } from "../BotSVG";
import { BotParts } from "../../../util/types";

const availableParts: Record<string, number> = {
  antenna: 3,
  eyes: 3,
  head: 3,
  neck: 3,
  body: 3,
};

const BOT_PARTS: Record<
  string,
  Record<number, (props: BotBodyPartProps) => ReactElement>
> = {};

Object.entries(availableParts).forEach(([type, count]) => {
  BOT_PARTS[type] = {};
  for (let version = 1; version <= count; version++) {
    BOT_PARTS[type][version] = require(`./${type}/${version
      .toString()
      .padStart(2, "0")}`).default;
  }
});

type BotPartType = keyof typeof BOT_PARTS;

export const getBotPart = (type: BotPartType, id: number) => {
  return BOT_PARTS[type][id];
};

export const getRandomBotPartByType = (partType: keyof BotParts): number => {
  const robotPart = BOT_PARTS[partType];
  const numberOfParts = Object.keys(robotPart).length;
  return Math.floor(Math.random() * numberOfParts) + 1;
};

export const getRandomBotParts = (): BotParts => {
  return {
    antenna: getRandomBotPartByType("antenna"),
    eyes: getRandomBotPartByType("eyes"),
    head: getRandomBotPartByType("head"),
    neck: getRandomBotPartByType("neck"),
    body: getRandomBotPartByType("body"),
  };
};

export const renderBotParts = (parts: BotParts) => {
  return {
    Antenna: getBotPart("antenna", parts.antenna),
    Eyes: getBotPart("eyes", parts.eyes),
    Head: getBotPart("head", parts.head),
    Neck: getBotPart("neck", parts.neck),
    Body: getBotPart("body", parts.body),
  };
};
