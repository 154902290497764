export const getTextColorForBackground = (backgroundColor: string) => {
  // Remove hash if present
  backgroundColor = backgroundColor.slice(backgroundColor[0] === "#" ? 1 : 0);

  // Convert 3-digit hex to 6-digits.
  if (backgroundColor.length === 3) {
    backgroundColor =
      backgroundColor[0] +
      backgroundColor[0] +
      backgroundColor[1] +
      backgroundColor[1] +
      backgroundColor[2] +
      backgroundColor[2];
  }

  const r = parseInt(backgroundColor.substr(0, 2), 16); // red
  const g = parseInt(backgroundColor.substr(2, 2), 16); // green
  const b = parseInt(backgroundColor.substr(4, 2), 16); // blue

  // Calculate brightness
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Return black for bright colors, white for dark colors
  return brightness > 155 ? "#000000" : "#ffffff";
};
