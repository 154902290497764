import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { AI_ID } from "../../App";
import { PlayersContext, RoomContext, SocketContext } from "../../App";
import { PromptLabel } from "./components/AnsweringPrompt";
import { LoadingBar } from "../components/LoadingBar";
import { Player } from "../../util/types";
import { BotSVG } from "../../assets/bots/BotSVG";
import CountUp from "react-countup";
import { playBotSound } from "../../assets/bots/sounds";

const ScoreContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 20px;
  width: 90%;
  max-width: 500px;
`;

const Scores = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 40px;
  max-width: 100%;
`;

const Score = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
`;

const DELAY_BEFORE_SCORES = 1.5;
const DELAY_BETWEEN_SCORES = 1.25;

export const HostShowScores = () => {
  const socket = useContext(SocketContext);
  const room = useContext(RoomContext);
  const players = useContext(PlayersContext);
  const [sortedPlayers, setSortedPlayers] = useState<Player[]>([]);

  if (!socket || !players) throw new Error("No socket or players!");

  const startNextRound = () => {
    socket.emit("nextRound", { roomId: room.id });
  };

  const updateScores = () => {
    // Sort players by score, filtering out the AI
    setSortedPlayers(
      Object.entries(players)
        .filter(([id]) => id !== AI_ID)
        .sort(([, a], [, b]) => b.score - a.score)
        .map(([, player]) => player)
    );
  };

  useEffect(() => {
    updateScores();
  }, [players]);

  return (
    <AnimatePresence>
      <ScoreContainer
        key="scores"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ staggerChildren: 0.5, delayChildren: 0.5 }}
      >
        <PromptLabel
          animate={{ opacity: 0.5 }}
          initial={{ opacity: 0 }}
          transition={{
            delay: DELAY_BEFORE_SCORES * (2 / 3),
            duration: DELAY_BEFORE_SCORES * (2 / 3),
          }}
        >
          Current Rankings
        </PromptLabel>
        <Scores>
          {sortedPlayers.reverse().map((player, index) => {
            // Calculate the user's "place" in the rankings, checking for ties
            // e.g. if player is first in the array, they are last, so (sortedPlayers.length - index)
            // if they are tied with the next player, they are the same place as the next player, etc.
            let place = index;
            if (index > 0 && player.score === sortedPlayers[index - 1].score) {
              place = index - 1;
            }
            playBotSound(
              player.bot.sound,
              false,
              0.35,
              DELAY_BEFORE_SCORES * 1000 + index * DELAY_BETWEEN_SCORES * 1000
            );

            return (
              <Score
                key={index}
                layout
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 20 }}
                transition={{
                  delay: DELAY_BEFORE_SCORES + index * DELAY_BETWEEN_SCORES,
                }}
              >
                <motion.div
                  layout
                  style={{
                    fontSize: "1.5em",
                  }}
                >
                  <CountUp
                    end={player.score || 0}
                    duration={DELAY_BETWEEN_SCORES}
                    delay={DELAY_BEFORE_SCORES + index * DELAY_BETWEEN_SCORES}
                  />
                </motion.div>
                <BotSVG
                  style={{
                    maxWidth: "100%",
                  }}
                  height={250 + 20 * place}
                  colors={player.bot.colors}
                  parts={player.bot.parts}
                />
                <motion.div
                  layout
                  style={{
                    fontWeight: "bold",
                    color: player.bot.colors.base,
                    marginTop: "10px",
                  }}
                >
                  {player.name}
                </motion.div>
              </Score>
            );
          })}
        </Scores>
        <LoadingBar
          duration={5}
          delay={
            DELAY_BEFORE_SCORES +
            sortedPlayers.length * DELAY_BETWEEN_SCORES +
            1.5
          }
          onComplete={startNextRound}
        />
      </ScoreContainer>
    </AnimatePresence>
  );
};
