import { MotionProps, motion } from "framer-motion";
import styled from "styled-components";
import { FaRobot, FaUser } from "react-icons/fa";

import { BotColors, BotParts } from "../../../util/types";
import { BotSVG } from "../../../assets/bots/BotSVG";

const BotContainer = styled(motion.div)<{ $highlightColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const BotSVGContainer = styled(motion.div)<{ $highlightColor: string }>`
  align-items: center;
  backdrop-filter: blur(10px);
  background: linear-gradient(
    135deg,
    ${({ $highlightColor }) => $highlightColor + "00"},
    ${({ $highlightColor }) => $highlightColor + "22"}
  );
  border: 1.5px ridge ${({ $highlightColor }) => $highlightColor + "66"};
  border-radius: 1rem;
  box-shadow: 0 0 8px 0 ${({ $highlightColor }) => $highlightColor + "55"};
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3px;
  padding: 0.75rem 0.4rem 0 0;
  position: relative;
  width: 100%;
  height: 100%;

  transition: background 0.5s, border 0.5s, box-shadow 0.5s;
`;

const PlayerName = styled(motion.p)`
  font-size: 1em;
  margin: 0;
  margin-top: 0.5rem;
  opacity: 0.85;
`;

const CardIcon = styled(motion.div)<{ $highlightColor: string }>`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 8px;
  right: 10px;

  &:has(span) {
    top: 3px;
    right: 8px;
  }

  span {
    margin-top: 1.5px;
    font-size: 0.75rem;
    font-weight: bold;
    color: ${({ $highlightColor }) => $highlightColor + "44"};
    font-family: "Roboto", sans-serif;
  }
`;

interface BotProps {
  height?: number;
  playerName?: string;
  colors: BotColors;
  parts: BotParts;
  isAI?: boolean;
}

export const VerticalBotCard = ({
  playerName,
  height = 150,
  colors,
  parts,
  isAI = false,
  ...props
}: BotProps & MotionProps) => {
  return (
    <BotContainer $highlightColor={colors.base} {...props}>
      <BotSVGContainer $highlightColor={colors.base}>
        <CardIcon $highlightColor={colors.base}>
          {isAI ? (
            <>
              <FaRobot size={20} color={colors.base + "55"} />
              <span>AI</span>
            </>
          ) : (
            <FaUser size={13} color={colors.base + "55"} />
          )}
        </CardIcon>
        <BotSVG colors={colors} parts={parts} height={height} />
      </BotSVGContainer>
      {playerName && <PlayerName>{playerName}</PlayerName>}
    </BotContainer>
  );
};
