import styled, { css, keyframes } from "styled-components";
import { BotBodyPartProps } from "../../BotSVG";

const generateRandomAnimation = () => {
  const duration = Math.random() * 3 + 7; // random number between 7 and 10
  const degree = Math.random() * 0.5 + 0.25; // random number between 0.25 and 0.75

  return css`
    ${keyframes`
      0%, 40%, 60%, 100% { transform: rotate(0deg); }
      45%, 55% { transform: rotate(${degree}deg); }
      50% { transform: rotate(-${degree}deg); }
    `} ${duration}s linear infinite
  `;
};

const AnimatedTie = styled.g`
  transform-origin: top;
  animation: ${generateRandomAnimation()};
`;

const Neck2 = ({
  colors: { accent, accentShadow, base, outline },
  outlineWeight,
}: BotBodyPartProps) => (
  <g id="neck">
    <g id="Back">
      <path
        d="M369.07,517.49c15.24,16.45-.89,49.59-.89,49.59,0,0,25.71,24.39,25.71,40.64,0,66.79-219.25,63.96-219.25,1.07,0-18.52,28.49-38.45,28.49-38.45,0,0-12.61-34.31-3.3-47.48,18.78-26.53,149.04-27.17,169.24-5.37Z"
        style={{
          fill: "#79a7d9",
          stroke: outline,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
    </g>
    <AnimatedTie id="tie">
      <path
        d="M299.28,860.34c-18.06,0-76.31-57.65-60.57-75.42,22.84-25.78,34.59-103.28,34.59-103.28,0,0-66.26-45.52-67.98-70.11-2.19-31.32,130.56-51.51,165.03-5.39,14.24,19.06-56.52,76.56-56.52,76.56,0,0,10.35,89.56,37.27,105.14,0,0-30.67,72.5-51.82,72.5Z"
        style={{
          fill: accent,
          strokeWidth: 0,
        }}
      />
      <path
        d="M321.14,666.47c-8.33,3.42-32.59,14.47-53.07,5.03-6.17-2.84,1.48,26.7-.53,33.36-3.27,10.79-5.12,26.75-11,41.14-4.99,12.24-14.28,22.24-15.04,38.79-.78,16.87,59.5-80.12,79.04-63.26,2.94,2.53-5.71-15.24-4.88-28.45s8.87-28,5.48-26.61Z"
        style={{
          fill: accentShadow,
          strokeWidth: 0,
        }}
      />
      <path
        d="M294.75,580.92c153.99,0,27.79,93.03,21.19,96.29-2.59,33.2,7.65,73.35,27.54,100.4,13.48,18.32-14.95,55.19-32.6,71.49-18.13,16.74-11.3,17.17-30.89,2.48-25.78-19.34-45.54-61.43-40.97-68.65,17.12-27.09,31.9-40.71,31.9-101.22-6.39-2.32-151.27-100.79,23.83-100.79Z"
        style={{
          fill: "none",
          stroke: outline,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
    </AnimatedTie>
    <g id="collar">
      <path
        d="M176.34,655.26c-3.88-3.88-8.77-39.6-2.11-50.28,23.1-37.04,65.72-54.27,83.42-39.48,43.2,36.11-.09,115.51-8.81,120.62,0,0-45.74-4.11-72.5-30.86Z"
        style={{
          fill: base,
          strokeWidth: 0,
        }}
      />
      <path
        d="M263.45,656.16s9.39-42.64-5.25-62.71c-4.33-5.94-15.22,13.97-12.49,22.65,11.71,37.19-.26,67.35-.26,67.35"
        style={{
          fill: "#79a7d9",
          strokeWidth: 0,
        }}
      />
      <path
        d="M259.07,562.1c13.88,13.88,39.16,32.58-1.38,114.82-7.34,14.88-25.74,5.55-27.93,5.07-1.93-.42-56.11-15.22-55.81-32.13.19-10.75.52-31.12.7-41.07.2-11.19,41.63-50.03,47.56-48.18,7.5,2.34,34.69-.68,36.86,1.49Z"
        style={{
          fill: "none",
          stroke: outline,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
      <g>
        <path
          d="M403.69,655.02c3.85-3.88,8.71-39.6,2.1-50.28-22.94-37.04-65.27-54.27-82.85-39.48-42.91,36.11.09,115.51,8.75,120.62,0,.01,45.43-4.1,72-30.86Z"
          style={{
            fill: base,
            strokeWidth: 0,
          }}
        />
        <path
          d="M317.19,655.93s-9.32-42.64,5.22-62.71c4.3-5.94,15.12,13.97,12.4,22.65-11.63,37.19.26,67.35.26,67.35"
          style={{
            fill: "#79a7d9",
            strokeWidth: 0,
          }}
        />
        <path
          d="M321.53,561.87c-13.79,13.88-38.9,32.58,1.37,114.82,7.29,14.88,25.57,5.55,27.74,5.07,1.91-.42,55.72-15.22,55.43-32.13-.19-10.75-.52-31.12-.69-41.07-.2-11.19-41.34-50.03-47.24-48.18-7.45,2.33-34.45-.68-36.61,1.49Z"
          style={{
            fill: "none",
            stroke: outline,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: outlineWeight,
          }}
        />
      </g>
    </g>
  </g>
);

export default Neck2;
