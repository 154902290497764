import { createContext, useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import { ThemeProvider } from "styled-components";

import { PlayerContainer } from "./screens/Player/PlayerContainer";
import { HostContainer } from "./screens/Host/HostContainer";
import { CurrentScreen } from "./CurrentScreen";

import { useSocket } from "./util/useSocket";
import { theme } from "./util/theme";
import { Player, PlayerID, ROOM_STATE, Room } from "./util/types";
import { preloadAudio } from "./util/useAudio";
import { preloadBotSounds } from "./assets/bots/sounds";

export const SERVER_URL = process.env.REACT_APP_SERVER_HOST || "localhost:3001";
export const AI_ID = "ai";

// Global context providers
export const SocketContext = createContext<Socket | null>(null);
export const RoomContext = createContext<Room>(null as unknown as Room);
export const MutedContext = createContext<boolean>(false);
export const PlayersContext = createContext<Record<PlayerID, Player>>({});
export const PlayerContext = createContext<(Player & { id: string }) | null>(
  null
);

export const App = () => {
  const [muted, setMuted] = useState<boolean>(false);
  const { socket, room, player, players, isHost } = useSocket();

  useEffect(() => {
    // preloadAudio();
    preloadBotSounds();
  }, []);

  const Screen = isHost ? (
    <HostContainer
      muted={muted}
      toggleMute={() => setMuted((prev) => !prev)}
      roomCodeVisible={room && room.state !== ROOM_STATE.lobby}
    >
      <CurrentScreen
        room={room}
        isHost={isHost}
        player={player}
        players={players}
      />
    </HostContainer>
  ) : (
    <PlayerContainer footerVisible={room !== null}>
      <CurrentScreen
        room={room}
        isHost={isHost}
        player={player}
        players={players}
      />
    </PlayerContainer>
  );

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MutedContext.Provider value={muted}>
          <SocketContext.Provider value={socket}>
            <RoomContext.Provider value={room}>
              <PlayersContext.Provider value={players}>
                <PlayerContext.Provider value={player}>
                  {Screen}
                </PlayerContext.Provider>
              </PlayersContext.Provider>
            </RoomContext.Provider>
          </SocketContext.Provider>
        </MutedContext.Provider>
      </ThemeProvider>
    </div>
  );
};
