import { BotBodyPartProps } from "../../BotSVG";

const Body2 = ({
  colors: { accent, base, outline },
  outlineWeight,
}: BotBodyPartProps) => (
  <g id="body">
    <g>
      <path
        d="M76.73,668.8c-1.56,9.06,29.77,27.04,11.36,34.79-32.45,13.66-1.99,202.08,6.81,203.49,46.26,7.41,330.69,10.3,380.84-1.64,23.31-5.55,32.7-170.18,11.99-214.45-8.2-17.53,54.66-35.12-12.47-55.47,0,0-72.39-12.94-157.51-12.8-108.48.18-235.84,16-241.02,46.08Z"
        style={{
          fill: base,
          strokeWidth: 0,
        }}
      />
      <path
        d="M101.25,828.69c-65.91-79.91-5.14,77.62-5.14,77.62,0,0,262.66,12.82,379.63-.88,11.42-1.34,45.73-161.35,14.9-105.35-61.23,111.2-193.1,70.97-193.1,70.97,0,0-146.35,18.2-196.29-42.36Z"
        style={{
          fill: "#79a7d9",
          strokeWidth: 0,
        }}
      />
      <path
        d="M73.56,671.93c-.84-.6.4-16.81,9.44-8.87,12.6,11.07,54.44,23.3,67.12,34.44,2.97,2.61-45.07-1.17-58.53,9.42,0,0-1.45-23.2-18.03-34.99Z"
        style={{
          fill: "#79a7d9",
          strokeWidth: 0,
        }}
      />
      <path
        d="M504.89,662.51c.71-.6-.34-16.81-7.98-8.87-10.65,11.07-46.01,23.3-56.73,34.44-2.51,2.61,38.09-1.17,49.47,9.42.01,0,1.23-23.21,15.24-34.99Z"
        style={{
          fill: "#79a7d9",
          strokeWidth: 0,
        }}
      />
      <path
        d="M290,623.1c6.75-25.59,9.37,197.58,6.85,171.24-7.12-74.28-48.7-116.52-48.7-137.79,0,0,33.89-3.26,41.85-33.45Z"
        style={{
          fill: "#79a7d9",
          strokeWidth: 0,
        }}
      />
      <path
        d="M78.25,665.55c46.45-46.45,348.94-49.74,421-22.44,26.29,9.96-11.53,47.87-11.53,47.87,0,0,19.09,31.7,11.53,114.93-2.95,32.43-8.32,84.27-18.25,95.22-3.82,4.21-59,7.12-84.42,7.76-42.83,1.07-140.73,2.46-209.44,0-44.64-1.6-96.94,6.76-99.47-8.42-4.66-28.02-10.13-55.98-13.08-84.19-10.91-104.35,12.24-112.43,22.2-118.78-9.83-7.15-25.07-25.42-18.54-31.95Z"
        style={{
          fill: "none",
          stroke: outline,
          strokeMiterlimit: 10,
          strokeWidth: outlineWeight,
        }}
      />
      <path
        id="Tag"
        d="M468.77,715.85c6.23,12.23,4,45.07-.89,59.41-1.73,5.06-79.04,15.18-83.13,12.35-8.56-5.9-17.89-31.28-17.89-46.2,0-4.78,100.03-29.27,101.91-25.56Z"
        style={{
          fill: accent,
          stroke: outline,
          strokeMiterlimit: 10,
          strokeWidth: outlineWeight,
        }}
      />
      <path
        d="M317.92,765.33c-1.45-10.23,6.29-15.08,14.52-15.59,10.71-.66,14.3,5.44,14.8,10.2.99,9.42-6.65,15.32-12,15.32-8.6,0-16.49-4.11-17.32-9.93Z"
        style={{
          fill: outline,
          strokeWidth: 0,
        }}
      />
      <path
        d="M317.92,848.33c-1.45-10.23,6.29-15.08,14.52-15.59,10.71-.66,14.3,5.44,14.8,10.2.99,9.42-6.65,15.32-12,15.32-8.6,0-16.49-4.11-17.32-9.93Z"
        style={{
          fill: outline,
          strokeWidth: 0,
        }}
      />
      <line
        x1={297.53}
        y1={899.51}
        x2={295.81}
        y2={635.51}
        style={{
          fill: "none",
          stroke: outline,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
    </g>
  </g>
);

export default Body2;
