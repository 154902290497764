import { useEffect, useState } from "react";
import { BotBodyPartProps } from "../../BotSVG";
import { v4 as uuidv4 } from "uuid";

const Body1 = ({
  colors: { accent, accentShadow, base, outline, shine, baseShadow },
  outlineWeight,
}: BotBodyPartProps) => {
  const [flickerCount, setFlickerCount] = useState(0);
  const animationId = uuidv4();

  useEffect(() => {
    const interval = setInterval(() => {
      setFlickerCount((prevCount) => prevCount + 1); // Increase flicker count
    }, Math.floor(Math.random() * 5000) + 7500); // Change delay every 10 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <g id="body">
      <style>
        {`
          @keyframes flicker${animationId}${flickerCount} {
            0%, 80%, 100% { fill: ${accent}; }
            50% { fill: ${shine}; }
          }

          .blinkingPath${animationId} {
            animation: flicker${animationId}${flickerCount} 0.5s ${
          Math.floor(Math.random() * 3) + 1
        };
            animation-fill-mode: forwards;
          }
        `}
      </style>
      <path
        d="M107.89,661.93c8.83-1.17,15.98-2.98,23.17-3.09,50.15-.72,99.96-5.56,149.86-10.16,27.55-2.54,55.24-5.35,83.18-4.73,31.97.71,63.81-5.02,95.94-3.41,19.88,1,37.37,14.39,37.8,33.89,1.05,47.33,1.49,83.31,1.59,130.71.04,19.7-1.32,40.23-1.73,60.13-.27,13.02,13.54,28.42.03,29.78-5.4.54-25.58,1.88-30.99,2.08-41.02,1.52-84.93-2.23-125.8,1.14-22.42,1.85-52.48,9.87-75.39,10.79-34.95,1.4-63.1-9.24-97.99-6.2-21.03,1.84-42.33.47-63.51.77-8.7.13-13.64-4.03-15.48-12.09-2.93-12.87-7.72-25.73-8.05-38.69-.84-32.8-7.79-65.24-1.77-98.58,3.78-20.96.92-43.11.97-64.72q.07-26.82,28.17-27.62Z"
        style={{
          fill: base,
          strokeWidth: 0,
        }}
      />
      <g id="Pattern_Image_copy_4" data-name="Pattern\xA0Image copy 4">
        <g>
          <path
            d="M302.27,703.29c-70.33,3.54-132.09-11.31-140.09-19.14-7.46-7.3,8.14-28.69,8.14-28.69,0,0,206.19-15.03,235.13-14.27,0,0,13.99,26.63,8.82,35.98-6.96,12.6-51.71,23.08-112,26.12Z"
            style={{
              fill: baseShadow,
              stroke: baseShadow,
              strokeMiterlimit: 10,
              strokeWidth: outlineWeight,
            }}
          />
          <path
            d="M122.55,755.79c39.84-3.32,48.25-16.67,48.95-10.11,10.35,96.14,26.03,107.26,75.68,117.38,3.73.76,7.88,14.48,5.79,34.05-2.37,22.22-167.01.33-168.99-4.77-3.86-9.93-5.67-73.21-2.88-96.91.58-5.03,24.58-38.23,41.45-39.64Z"
            style={{
              fill: baseShadow,
              strokeWidth: 0,
            }}
          />
          <path
            d="M284.86,784.5c-.88-4.34-6.01-45.4,34.14-54.05,28.54-6.15,52.27,13.88,63.2,25.59,17.09,18.29,2,68.33-48.73,64.19-26.65-2.19-44.02-13.19-48.61-35.73Z"
            style={{
              fill: baseShadow,
              strokeWidth: 0,
            }}
          />
          <path
            d="M459.4,750.96c.02.59,6.66,38.6-1.22,47.7-8.58,9.91-43.52,8.22-53.05-.81-8.38-7.93-2.05-41.22-2.55-45.57-1.11-9.69,56.4-11.29,56.82-1.32Z"
            style={{
              fill: baseShadow,
              strokeWidth: 0,
            }}
          />
        </g>
      </g>
      <g id="Layer_98_Image_copy_4" data-name="Layer 98\xA0Image copy 4">
        <g>
          <path
            d="M236.97,822.01c1.3-18.94-1.05-39.52-3.67-52.33"
            style={{
              fill: "none",
              stroke: outline,
              strokeLinecap: "round",
              strokeMiterlimit: 10,
              strokeWidth: outlineWeight,
            }}
          />
          <path
            d="M404.06,747.28c11.2-.07,15.74.06,28.62.03,4.03.05,23.15,0,23.15,0"
            style={{
              fill: "none",
              stroke: outline,
              strokeLinecap: "round",
              strokeMiterlimit: 10,
              strokeWidth: outlineWeight,
            }}
          />
          <path
            d="M501.4,788.85c-2.6,54.57,4.57,106.84-3.67,110.21-16.81,6.88-111.57.29-184.1,5.3-81.95,5.66-213.57-.58-226.13-4-7.01-1.91-10.09-59.78-10.09-122.32,0-30.62-1.2-76.9-1.09-107.38.08-22.49,3.62-3.87,213.67-30.41,2.02-.26,206.61-4.17,207.36,14.24,1.04,25.62,4.96,115.11,4.05,134.36Z"
            style={{
              fill: "none",
              stroke: outline,
              strokeMiterlimit: 10,
              strokeWidth: outlineWeight,
            }}
          />
          <path
            d="M256.45,858.98c-35.46,7.23-107.91.93-132.97-.05-8.62-.34-8.76-92.37-6.41-103.77,2.12-10.27,127.11-26.94,134.79-22.78,7.82,4.22,20.82,123.29,4.59,126.6Z"
            style={{
              fill: "none",
              stroke: outline,
              strokeMiterlimit: 10,
              strokeWidth: outlineWeight,
            }}
          />
          <path
            className="blinkingPath"
            d="M333.39,721.91c-36.68,9.12-35.6,26.68-37.29,40.15-4.97,39.79,32,37.13,46.61,35.28,13.79-1.75,38.98-18.99,38.98-44.19,0,0-13.6-39.87-48.3-31.24Z"
            style={{
              fill: accent,
              strokeWidth: 0,
            }}
          />
          <path
            className="blinkingPath"
            d="M297.5,761.03c3.93,27.17,19.9,41.07,42.34,37.88,8.64-1.23,40.89-26.06,39.59-41.65-.71-8.54-18.19,14.38-41.74,13.14-21.89-1.14-40.57-11.98-40.19-9.37Z"
            style={{
              fill: accentShadow,
              strokeWidth: 0,
            }}
          />
          <path
            d="M294.32,757.93c7.12-36.49,50.63-45.77,70.33-28.3,15.05,13.35,17.16,27.75,9.73,42.56-9.9,19.73-36.1,29.03-50.09,26.66-12.5-2.12-29.97-16.62-29.97-40.92Z"
            style={{
              fill: "none",
              stroke: outline,
              strokeMiterlimit: 10,
              strokeWidth: outlineWeight,
            }}
          />
        </g>
      </g>
    </g>
  );
};

export default Body1;
