import { BotBodyPartProps } from "../../BotSVG";

const Head1 = ({
  colors: { base, outline, shine, baseShadow },
  outlineWeight,
}: BotBodyPartProps) => (
  <g id="head">
    <path
      d="M116.46,529.09c3.31-32.86,2.29-59.47,2.31-66.56.14-33.76-5.23-33.01-5.65-66.76-.18-14.97-.02-72.49-1.91-87.33-3.35-26.23,25.96-22.7,44.35-21.43,45.08,3.11,92.03-5.89,136.85-4.63,44.59,1.25,83.25,3.19,127.88,3.31,21.68.06,33.36-1.28,33,19.89-.82,48.54,4.54,96.84,4.6,145.4.03,24.63,2.86,49.49,2.83,74.33,0,2.96.21,5.94-.07,8.88-4.1,43.15.7,35.82-36.55,41.53-17.49,2.68-38.68-2.97-55.9-1.02-59.22,6.7-117.96,17.1-177.79,6.48-14.6-2.59-35.74,6.44-50.74,3.77-11.56-2.05-17.94-7.29-20.62-18.87-2.7-11.82-1.66-23.59-2.59-36.99Z"
      style={{
        fill: base,
        strokeWidth: 0,
      }}
    />
    <g>
      <path
        d="M453.11,573.7c-6.36,1.11-158.45,9.19-163.18,9.24-80.7.85-96.19,6.62-160.65.7-17.62-1.62-18.32-67.74-10.82-69.26,25.03-5.08,37.3-3.92,66.06,28.54,12.32,13.9,47.32,8.14,44.79-8.94-2.16-14.63,19.76-22.2,55.9-22.2,40,0,59.92,2.44,61.11,18.37,1.5,20.08,32.81,23.58,47.71,8.67,23.36-23.36,7.84-37.4,66.78-43.18,9.63-.96-7.7,71.58-7.7,78.06Z"
        style={{
          fill: baseShadow,
          strokeWidth: 0,
        }}
      />
      <path
        d="M208.09,434.12c-5.41-42.3,35.48-41.69,73.16-43.13,23.15-.89,77.08,1.64,81.02,32,5.39,41.52-54.58,40.54-77,42.53-21.04,1.87-71.64,11.93-77.18-31.4Z"
        style={{
          fill: baseShadow,
          strokeWidth: 0,
        }}
      />
      <path
        d="M410.43,313.78c-7.47,1.05-11.75.22-11.35-8.25.33-7.05,11.22-9.44,13.33-4.91,1.82,3.96,4.9,8.36-1.98,13.16Z"
        style={{
          fill: baseShadow,
          strokeWidth: 0,
        }}
      />
      <path
        d="M430.81,328.88c-4.99-1.58-8.08-6.88-6.49-11.87,1.24-3.9,4.74-5.32,10.35-4.3,6.1,1.11,7.16,6.43,5.35,10.77-2.14,5.17-5.41,6.6-9.21,5.4Z"
        style={{
          fill: baseShadow,
          strokeWidth: 0,
        }}
      />
    </g>
    <g id="Head_Image_copy_5" data-name="Head\xA0Image copy 5">
      <g>
        <path
          d="M234.27,304.68c7.26-5.25-93.37,5.12-100.54,4.74-6.86-.36,20.5,15.13,32.25,13.32,20.52-3.14,53.17-7.11,68.29-18.06Z"
          style={{
            fill: "none",
            stroke: shine,
            strokeMiterlimit: 10,
            strokeWidth: outlineWeight,
          }}
        />
        <path
          d="M283.95,305.64c-3.29-.74-21.7,1.64-24.32.71"
          style={{
            fill: "none",
            stroke: shine,
            strokeLinecap: "round",
            strokeMiterlimit: 10,
            strokeWidth: outlineWeight,
          }}
        />
        <path
          d="M451.64,288.09c-16.35-10.84-131.02-4.22-151.19-3.48-24.78.91-172.7-7.47-182.87,6.71-14.2,19.82-6.58,77.54-5,104.46,2.17,36.91,8.08,62.6,6.27,97.28-1.45,27.88-4.26,61.03,7.01,86.31,7.31,16.39,61.18,2.95,90.91,5.96,30.1,3.04,108.46-.29,131.02-2.04,31.15-2.42,86.93,6.39,106.29-16.27,4.51-5.28,7.45-39.8,2.96-79.36-2.21-19.43-1.63-62.29,0-98.91,1.54-34.38,5.29-93.58-5.4-100.66Z"
          style={{
            fill: "none",
            stroke: outline,
            strokeMiterlimit: 10,
            strokeWidth: outlineWeight,
          }}
        />
      </g>
    </g>
  </g>
);

export default Head1;
