import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { motion } from "framer-motion";
import { FaPencilAlt, FaCheck, FaMobileAlt, FaTimes } from "react-icons/fa";

import {
  MutedContext,
  PlayersContext,
  RoomContext,
  SocketContext,
} from "../../App";

import {
  PromptContainer,
  PromptLabel,
  PromptImage,
  Prompt,
  PlayerStatuses,
  PlayerStatusContainer,
  PlayerStatus,
  PromptText,
  WordLimit,
} from "./components/AnsweringPrompt";
import { HorizontalBotCard } from "./components/HorizontalBotCard";
import { Timer } from "../components/Timer";

import AnsweringTheme from "../../assets/sounds/theme/questions.mp3";
import PlayerAnsweredSound1 from "../../assets/sounds/answers/1.mp3";
import PlayerAnsweredSound2 from "../../assets/sounds/answers/2.mp3";
import PlayerAnsweredSound3 from "../../assets/sounds/answers/3.mp3";
import PlayerAnsweredSound4 from "../../assets/sounds/answers/4.mp3";
import PlayerAnsweredSound5 from "../../assets/sounds/answers/5.mp3";
import PlayerAnsweredSound6 from "../../assets/sounds/answers/6.mp3";

import { useAudio } from "../../util/useAudio";
import { log } from "../../util/log";
import { PlayerID } from "../../util/types";

export const HostAnsweringPrompt = () => {
  const socket = useContext(SocketContext);
  const theme = useContext(ThemeContext);
  const room = useContext(RoomContext);
  const players = useContext(PlayersContext);
  const muted = useContext(MutedContext);

  const { round, prompt } = room;
  if (!socket) throw new Error("Socket not found");

  const [answersReceived, setAnswersReceived] = useState<PlayerID[]>([]);
  const [playAnsweringTheme, pauseAnsweringTheme] = useAudio(AnsweringTheme, {
    loop: true,
  });

  useEffect(() => {
    playAnsweringTheme();

    return () => {
      pauseAnsweringTheme();
    };
  }, []);

  let answerSound: HTMLAudioElement = new Audio();
  answerSound.volume = 0.4;

  const playerAnsweredSounds = [
    PlayerAnsweredSound1,
    PlayerAnsweredSound2,
    PlayerAnsweredSound3,
    PlayerAnsweredSound4,
    PlayerAnsweredSound5,
    PlayerAnsweredSound6,
  ];

  useEffect(() => {
    if (muted) return;
    const answerCount = Object.keys(answersReceived).length;
    if (answerCount === 0) return;
    const soundIndex = answerCount - (1 % playerAnsweredSounds.length);
    if (!answerSound.paused) answerSound.pause();
    answerSound = new Audio(playerAnsweredSounds[soundIndex]);
    answerSound.play();
  }, [answersReceived]);

  useEffect(() => {
    // Only the host gets this when a player submits an answer
    socket.on("answerReceived", ({ id }: { id: PlayerID }) => {
      if (!players[id]) {
        log(`Player ${id} not found`, "error");
        return;
      }
      setAnswersReceived((prev) => [...prev, id]);
    });

    return () => {
      socket.off("answerReceived");
    };
  }, [players]);

  if (!prompt) {
    return <h1>Waiting for prompt...</h1>;
  }

  function renderPromptExtraInfo() {
    switch (prompt?.type) {
      case "rewrite":
        return (
          <PromptText
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              delay: 2.5,
              duration: 2,
              type: "spring",
            }}
          >
            "{prompt.text}"
          </PromptText>
        );
      case "image":
        return (
          <PromptImage
            src={prompt.url}
            initial={{ scale: 0, opacity: 0, filter: "blur(10px)" }}
            animate={{ scale: 1, opacity: 1, filter: "blur(0px)" }}
            transition={{
              delay: 1,
              duration: 1,
              filter: {
                delay: 1.5,
                duration: 2,
              },
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          />
        );
      default:
        return <></>;
    }
  }

  return (
    <PromptContainer>
      <Timer />
      <PromptLabel
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 0.5, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        ROUND {round + 1}
      </PromptLabel>
      <Prompt
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1 }}
      >
        {prompt.prompt}
      </Prompt>
      <WordLimit
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.5 }}
      >
        <span>... in</span> {prompt.wordLimit} words <span>or less!</span>
      </WordLimit>
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {renderPromptExtraInfo()}
      </div>
      <PromptLabel
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 0.5, y: 0 }}
        transition={{ delay: prompt.type === "text" ? 2.5 : 4.5 }}
      >
        <FaMobileAlt size={25} />
        Answer the prompt on your device now!
      </PromptLabel>
      <PlayerStatuses>
        {Object.entries(players).map(([id, player], index) => (
          <PlayerStatusContainer
            key={player.name}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: player.isConnected ? 1 : 0.5, y: 0 }}
            transition={{ delay: 5 + index * 0.2 }}
          >
            <HorizontalBotCard
              playerName={player.name}
              colors={player.bot.colors}
              parts={player.bot.parts}
              isAI={player.isAI}
            />
            <PlayerStatus>
              {answersReceived.includes(id) ? (
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ rotate: 360, scale: 1 }}
                  transition={{
                    duration: 1,
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                >
                  <FaCheck size={20} color={theme?.colors.success || "green"} />
                </motion.div>
              ) : player.isConnected ? (
                <motion.div
                  style={{ opacity: 0.5 }}
                  animate={{
                    rotate: [0, -20, 0],
                    x: [-5, 0, -5],
                  }}
                  transition={{
                    duration: Math.random() * 1.5 + 1,
                    repeat: Infinity,
                    repeatType: "reverse",
                  }}
                >
                  <FaPencilAlt size={20} />
                </motion.div>
              ) : (
                <motion.div style={{ opacity: 1 }} animate={{ opacity: 1 }}>
                  <FaTimes size={20} color={theme?.colors.error || "red"} />
                </motion.div>
              )}
            </PlayerStatus>
          </PlayerStatusContainer>
        ))}
      </PlayerStatuses>
    </PromptContainer>
  );
};
