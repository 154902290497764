import { motion } from "framer-motion";
import { useContext, useEffect, useMemo, useState } from "react";

import {
  Container,
  PlayerList,
  PlayerListContainer,
  RoomCode,
  RoomCodeContainer,
  RoomCodeText,
} from "./components/Lobby";
import { QRCodeComponent } from "./components/Lobby";
import { Button } from "../components/Button";

import { useAudio } from "../../util/useAudio";
import {
  MutedContext,
  PlayersContext,
  RoomContext,
  SocketContext,
} from "../../App";
import { Player } from "../../util/types";

import { VerticalBotCard } from "./components/VerticalBotCard";
import LobbyTheme from "../../assets/sounds/theme/lobby.mp3";
import PlayerJoinSound1 from "../../assets/sounds/join/1.mp3";
import PlayerJoinSound2 from "../../assets/sounds/join/2.mp3";
import PlayerJoinSound3 from "../../assets/sounds/join/3.mp3";
import PlayerJoinSound4 from "../../assets/sounds/join/4.mp3";
import PlayerJoinSound5 from "../../assets/sounds/join/5.mp3";
import PlayerJoinSound6 from "../../assets/sounds/join/6.mp3";
import PlayerJoinSound7 from "../../assets/sounds/join/7.mp3";
import { playBotSound } from "../../assets/bots/sounds";

const MIN_PLAYERS = 2;

const PlayerListComponent = ({ newPlayers }: { newPlayers: Player[] }) => {
  const [prevPlayersLength, setPrevPlayersLength] = useState(
    Object.keys(newPlayers).length
  );

  useEffect(() => {
    setPrevPlayersLength(Object.keys(newPlayers).length);
  }, [newPlayers]);

  return (
    <PlayerList layout>
      {newPlayers?.map((player: Player, index: number) => {
        const isNewPlayer = index >= prevPlayersLength - 1;
        const animationProps = isNewPlayer
          ? {
              initial: { scale: 0, opacity: 0 },
              animate: { scale: [0, 1.1, 1], opacity: 1 },
              transition: { duration: 0.4 },
              exit: { scale: 0, opacity: 0 },
            }
          : {
              initial: { scale: 1 },
              animate: { scale: 1 },
              transition: { duration: 0.4 },
              exit: { scale: 1 },
            };

        return (
          <VerticalBotCard
            key={player.name}
            playerName={player.name}
            isAI={player.isAI}
            height={150}
            colors={player.bot.colors}
            parts={player.bot.parts}
            {...animationProps}
            layout
          />
        );
      })}
    </PlayerList>
  );
};

export const HostLobby = () => {
  const socket = useContext(SocketContext);
  const room = useContext(RoomContext);
  const players = useContext(PlayersContext);
  const muted = useContext(MutedContext);

  if (!socket || !room) {
    throw new Error("Server isn't currently running...");
  }

  const [playLobbyTheme, pauseLobbyTheme] = useAudio(LobbyTheme, {
    loop: true,
  });

  useEffect(() => {
    playLobbyTheme();
    socket.on("playerBotChanged", ({ bot }): void => {
      playBotSound(bot.sound, false, 0.35);
    });

    return () => {
      pauseLobbyTheme();
    };
  }, []);

  const newPlayers = useMemo(() => Object.values(players), [players]);

  const playerJoinSounds = [
    PlayerJoinSound1,
    PlayerJoinSound2,
    PlayerJoinSound3,
    PlayerJoinSound4,
    PlayerJoinSound5,
    PlayerJoinSound6,
    PlayerJoinSound7,
  ];

  useEffect(() => {
    if (muted) return;
    const playerCount = Object.keys(players).length;
    if (!players || playerCount === 1) return;
    const soundIndex = playerCount - (1 % playerJoinSounds.length);
    const playerJoinSound = new Audio(playerJoinSounds[soundIndex]);
    playerJoinSound.volume = 0.65;
    playerJoinSound.play();
  }, [Object.keys(players).length]);

  const numPlayers = players ? Object.keys(players).length : 0;

  return (
    <Container>
      <RoomCodeContainer>
        <div className="header">
          <RoomCodeText initial={{ scale: 0 }} animate={{ scale: 1 }}>
            Room Code:
          </RoomCodeText>
          <RoomCode
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.25 }}
          >
            {room.id}
          </RoomCode>
        </div>
        <motion.p className="tip" initial={{ scale: 0 }} animate={{ scale: 1 }}>
          To join, go to <span>{window.location.hostname}</span> or scan the QR
          code below:
        </motion.p>
        <motion.div
          className="qr-code-container"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5 }}
        >
          {QRCodeComponent(room.id)}
        </motion.div>
      </RoomCodeContainer>
      <PlayerListContainer>
        <div>
          <motion.p
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 0.4, scale: 1 }}
            style={{
              fontSize: "1rem",
              fontWeight: 600,
            }}
            className="title"
          >
            {players ? "PLAYERS" : ""}
          </motion.p>
          <PlayerListComponent newPlayers={newPlayers} />
        </div>

        {players && numPlayers >= MIN_PLAYERS ? (
          <Button
            disabled={numPlayers < MIN_PLAYERS}
            onClick={() => socket.emit("startGame", { roomId: room.id })}
          >
            Start Game
          </Button>
        ) : (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            transition={{ delay: 1, duration: 2 }}
            className="tip"
          >
            {`Waiting for at least ${
              MIN_PLAYERS - (numPlayers || 0)
            } more player${
              MIN_PLAYERS - (numPlayers || 0) === 1 ? "" : "s"
            }...`}
          </motion.p>
        )}
      </PlayerListContainer>
    </Container>
  );
};
