import styled from "styled-components";
import { HTMLMotionProps, motion } from "framer-motion";
import React from "react";

export const StyledButton = styled(motion.button)<{
  $variant?: "highlight" | "success" | "error";
}>`
  border: ${({ theme, $variant }) =>
    theme.border.button[$variant ?? "highlight"]};
  border-radius: 8px;
  box-shadow: ${({ theme, $variant }) =>
    theme.glow.button[$variant ?? "highlight"]};
  background: ${({ theme, $variant }) =>
    theme.gradients.button[$variant ?? "highlight"]};
  color: ${(props) => props.theme.colors.text};
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 1.5em;
  height: 70px;
  margin: 0 auto;
  outline: none;
  transition: opacity 0.5s;
  width: 100%;
  transition: scale 0.25s, box-shadow 0.25s;

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover &:not(disabled) {
    box-shadow: 0 0 16px 0 ${({ theme }) => theme.colors.highlight + "55"};
  }
`;

type ButtonProps = HTMLMotionProps<"button"> & {
  color?: "highlight" | "success" | "error";
};

export const Button = (props: ButtonProps & { children: React.ReactNode }) => {
  return (
    <StyledButton
      $variant={props.color}
      whileHover={{
        scale: 1.05,
        transition: { duration: 0.1, ease: "easeIn" },
      }}
      whileTap={{ scale: 0.95, transition: { duration: 0.1, ease: "easeIn" } }}
      {...props}
    >
      {props.children}
    </StyledButton>
  );
};
