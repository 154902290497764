import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "./components/Button";
import { SocketContext } from "../App";
import { log } from "../util/log";
import { getRandomBot } from "../assets/bots/utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 360px;
  text-align: center;
  width: 80%;
`;

const Tip = styled(motion.p)`
  font-size: 1em;
  margin: 0;
`;

const OrLabel = styled(motion.div)`
  align-items: center;
  display: flex;
  font-size: 1em;
  margin: auto;
  text-align: center;

  &::before,
  &::after {
    content: "";
    border-bottom: 3px solid #ffffff22;
    flex: 1;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
`;

const Input = styled(motion.input)<{ $border?: "success" | "error" }>`
  background-color: #ffffff22;
  border-radius: 8px;
  border: ${({ theme, $border }) =>
    $border ? theme.border.button[$border] : "none"};
  box-sizing: border-box;
  color: white;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1.5em;
  height: 70px;
  margin: auto;
  outline: none;
  padding: 0 15px;
  text-transform: uppercase;
  text-align: center;
  transition: border-color 0.5s, border-width 0.5s;
  width: 100%;

  &::placeholder {
    color: #ffffff88;
  }
`;

const getURLRoomID = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("room")?.toUpperCase();
};

export const Start = () => {
  const socket = useContext(SocketContext);

  const [name, setName] = useState("");
  const [roomId, setRoomId] = useState("");
  const [loadingRoom, setLoadingRoom] = useState(false);
  const [roomExists, setRoomExists] = useState<boolean | null>(null);
  const [playerNameError, setPlayerNameError] = useState<string | undefined>(
    undefined
  );
  const [playerCanReconnect, setPlayerCanReconnect] = useState<boolean>(false);

  // TODO: Create error screen
  if (!socket) {
    throw new Error("Server isn't currently running...");
  }

  useEffect(() => {
    // Check for "?room=XXXX" in the URL, and set the room ID if found
    if (roomId.length === 0) {
      const URLRoomID = getURLRoomID();
      if (URLRoomID) {
        setRoomId(URLRoomID);
        socket.connect();
        setTimeout(() => {
          socket.emit("checkRoomExists", { roomId: URLRoomID });
        }, 250);
      }
      if (socket.connected) socket.disconnect();
    }

    socket.on("roomExists", (exists: boolean) => {
      log(`Room exists: ${exists}`);
      setRoomExists(exists);
      if (!exists) {
        // If there was a URL parameter for the room, clear the input
        const urlParams = new URLSearchParams(window.location.search);
        const URLRoomID = getURLRoomID();
        if (URLRoomID) {
          setRoomId("");
          urlParams.delete("room");
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          );
        }
      }
    });

    socket.on("playerExists", (error: string | undefined) => {
      if (error) setPlayerNameError(error);
    });

    socket.on("playerReconnected", () => {
      log("Player reconnected");
      setPlayerNameError(undefined);
      setPlayerCanReconnect(true);
    });

    return () => {
      setLoadingRoom(false);
      socket.off("roomExists");
      socket.off("playerExists");
    };
  }, []);

  const createRoom = () => {
    log("Creating room...");
    setLoadingRoom(true);
    if (!socket.connected) socket.connect();
    socket.emit("createRoom");
    // If the room isn't created after 5 seconds, stop loading and disconnect
    setTimeout(() => {
      setLoadingRoom((loadingRoom) => {
        if (loadingRoom) {
          socket.disconnect();
          return false;
        }
        return loadingRoom;
      });
    }, 5000);
  };

  const joinRoom = () => {
    log(`Joining room ${roomId} with name ${name}...`);
    socket.emit("joinRoom", { name, roomId, bot: getRandomBot() });
    setTimeout(() => {
      socket.emit("checkRoomExists", { roomId });
    }, 1000);
  };

  const updateRoomId = (e: ChangeEvent<HTMLInputElement>) => {
    // Prevent any non-alphabetic characters from being entered
    const roomId = e.target.value.replace(/[^a-zA-Z]/g, "").toUpperCase();
    setRoomId(roomId);
    if (roomId.length === 4) {
      if (!socket.connected) socket.connect();
      log(`Checking room ${roomId}...`);
      socket.emit("checkRoomExists", { roomId });
    } else if (roomExists) {
      setRoomExists(null);
    } else {
      setPlayerCanReconnect(false);
      setPlayerNameError(undefined);
      setRoomExists(null);
      if (socket.connected) socket.disconnect();
    }
  };

  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  const updateName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
    setName(name);
    setPlayerNameError(undefined);
    if (roomExists && name.length > 0) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        socket.emit("checkPlayerExists", { roomId, name });
      }, 500);
    }
  };

  return (
    <Container>
      <AnimatePresence>
        {roomId.length === 0 && (
          <>
            <Button type="submit" onClick={createRoom} disabled={loadingRoom}>
              {loadingRoom ? "LOADING..." : "HOST"}
            </Button>
            <Tip
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.25 }}
              className="info"
            >
              Create a game and invite friends to join!
            </Tip>
            <OrLabel
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              transition={{ duration: 0.8 }}
            >
              OR
            </OrLabel>
          </>
        )}
      </AnimatePresence>
      <Input
        type="text"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.25 }}
        maxLength={4}
        value={roomId}
        placeholder="Room code"
        onChange={updateRoomId}
        disabled={loadingRoom}
        $border={
          roomExists ? "success" : roomId.length === 4 ? "error" : undefined
        }
      />
      <Tip
        initial={{ opacity: 0, y: -40 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="info"
      >
        {roomExists === null
          ? "Enter a room code to join an existing game!"
          : roomExists
          ? "Room found! Enter your name and join!"
          : "Room not found. Please check the code and try again."}
      </Tip>
      {roomExists && (
        <>
          <Input
            autoFocus={roomExists}
            type="text"
            initial={{ y: -40 }}
            animate={{ y: 0 }}
            value={name}
            placeholder="Your name"
            onChange={updateName}
            maxLength={12}
            $border={
              name.length > 0
                ? playerNameError
                  ? "error"
                  : "success"
                : undefined
            }
          />
          <AnimatePresence>
            {playerNameError && (
              <Tip
                key="nameExists"
                initial={{ opacity: 0, y: -40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -40 }}
                className="info"
              >
                {playerNameError}
              </Tip>
            )}
          </AnimatePresence>
        </>
      )}
      {roomId.length > 0 && (
        <Button
          initial={{ y: -40 }}
          animate={{ y: 0 }}
          type="submit"
          disabled={
            !roomExists ||
            (playerNameError && playerNameError.length > 0) ||
            name.length === 0
          }
          onClick={joinRoom}
          color="success"
        >
          {playerCanReconnect ? "RECONNECT" : "JOIN"}
        </Button>
      )}
    </Container>
  );
};
