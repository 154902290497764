import { useContext, useEffect, useState } from "react";
import { RoomContext, SocketContext } from "../../App";
import { ThemeContext } from "styled-components";
import { Button } from "../components/Button";
import { AnimatePresence } from "framer-motion";
import { FaCheck } from "react-icons/fa";
import {
  CheckmarkContainer,
  PromptContainer,
  PromptLabel,
  PromptText,
  PromptInputContainer,
  PromptInput,
  WordCounter,
} from "./components/AnsweringPrompt";
import { Timer } from "../components/Timer";

export const PlayerAnsweringPrompt = () => {
  const theme = useContext(ThemeContext);
  const socket = useContext(SocketContext);
  const room = useContext(RoomContext);

  const [submitted, setSubmitted] = useState(false);
  const [typing, setTyping] = useState(false);

  if (!socket || !room || !room.prompt) {
    return <h1>An error has occurred, refresh and try again.</h1>;
  }

  const [input, setInput] = useState("");
  const wordCount = input.length === 0 ? 0 : input.trim().split(/\s+/).length;

  const { wordLimit } = room.prompt;
  const atWordLimit = wordCount === wordLimit;
  const overWordLimit = wordCount > wordLimit;

  const { prompt } = room;

  useEffect(() => {
    socket.on("submitPlayerAnswers", () => {
      setSubmitted((submitted) => {
        if (!submitted) {
          socket.emit("playerAnswer", {
            roomId: room.id,
            answer: input,
          });
        }
        return true;
      });
    });

    return () => {
      socket.off("submitPlayerAnswers");
    };
  }, [socket, input]);

  const handleSubmit = () => {
    socket.emit("playerAnswer", {
      roomId: room.id,
      answer: input,
    });
    setSubmitted(true);
  };

  const Checkmark = () => (
    <CheckmarkContainer
      style={{
        height: "150px",
      }}
      initial={{ scale: 0 }}
      animate={{ rotate: 360, scale: 1 }}
      exit={{ scale: 0 }}
      transition={{
        duration: 1,
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
    >
      <FaCheck size={100} color={theme?.colors.success || "green"} />
    </CheckmarkContainer>
  );

  return (
    <PromptContainer>
      <PromptLabel>ROUND {room.round + 1}</PromptLabel>
      <PromptText>
        {prompt.type === "rewrite" ? (
          <>
            <p className="text">{prompt.prompt}</p>
            {prompt.text}
          </>
        ) : (
          prompt.prompt
        )}
      </PromptText>
      <Timer fixed={!typing} />
      <AnimatePresence>
        {submitted ? (
          <Checkmark />
        ) : (
          <PromptInputContainer>
            <WordCounter $correct={atWordLimit} $incorrect={overWordLimit}>
              {wordCount}
              {atWordLimit ? "" : "/" + wordLimit} word
              {wordCount != 1 && "s"}
            </WordCounter>
            <PromptInput
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ delay: 0.5 }}
              exit={{ opacity: 0 }}
              placeholder="Type an AI-sounding response..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onFocus={() => setTyping(true)}
              onBlur={() => setTyping(false)}
              maxLength={wordLimit ? wordLimit * 16 : 256}
              $correct={atWordLimit}
              $incorrect={overWordLimit}
            />
          </PromptInputContainer>
        )}
      </AnimatePresence>
      <Button onClick={handleSubmit} disabled={submitted || input.length === 0}>
        {submitted ? "Submitted" : "Submit"}
      </Button>
    </PromptContainer>
  );
};
