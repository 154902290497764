import React from "react";
import { Player, PlayerID, Room, ROOM_STATE } from "./util/types";
import { Start } from "./screens/Start";
import { HostLobby } from "./screens/Host/Lobby";
import { HostAnsweringPrompt } from "./screens/Host/AnsweringPrompt";
import { HostPlayerVoting } from "./screens/Host/PlayerVoting";
import { HostAIRanking } from "./screens/Host/AIRanking";
import { HostShowScores } from "./screens/Host/ShowScores";
import { HostFinale } from "./screens/Host/Finale";
import { NoAnswers } from "./screens/Host/NoAnswers";
import { PlayerAnsweringPrompt } from "./screens/Player/AnsweringPrompt";
import { PlayerPlayerVoting } from "./screens/Player/PlayerVoting";
import { Splash } from "./screens/Player/Splash";
import styled from "styled-components";
import { PlayerLobby } from "./screens/Player/Lobby";
import { motion } from "framer-motion";

export const ScreenContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100svh;
  overflow: hidden;
  position: fixed;
  width: 100vw;
  background: black;
  background: linear-gradient(152deg, #361717 0%, #181834 100%);
  background-size: 150% 150%;
  animation: gradient 15s ease infinite;
  color: ${(props) => props.theme.colors.text};
`;

export const CurrentScreen = React.memo(
  ({
    room,
    isHost,
  }: {
    room: Room;
    isHost: boolean;
    player: Player | null;
    players: Record<PlayerID, Player>;
  }) => {
    if (!room) {
      return <Start />;
    } else {
      switch (room.state) {
        case ROOM_STATE.lobby:
          return isHost ? <HostLobby /> : <PlayerLobby />;
        case ROOM_STATE.answering_prompt:
          return isHost ? <HostAnsweringPrompt /> : <PlayerAnsweringPrompt />;
        case ROOM_STATE.no_answers:
          return isHost ? <NoAnswers /> : <Splash />;
        case ROOM_STATE.showing_answers:
          return isHost ? <HostPlayerVoting /> : <Splash />;
        case ROOM_STATE.player_voting:
          return isHost ? <HostPlayerVoting /> : <PlayerPlayerVoting />;
        case ROOM_STATE.ai_ranking:
          return isHost ? <HostAIRanking /> : <Splash />;
        case ROOM_STATE.show_scores:
          return isHost ? <HostShowScores /> : <Splash />;
        case ROOM_STATE.end:
          return isHost ? <HostFinale /> : <Splash />;
        default:
          return <></>;
      }
    }
  },
  (prevProps, nextProps) => {
    if (!prevProps.room || !nextProps.room) return false;
    // Only re-render if the room state or isHost changes
    return (
      prevProps.player === nextProps.player &&
      prevProps.players === nextProps.players &&
      prevProps.isHost === nextProps.isHost &&
      prevProps.room.state === nextProps.room.state &&
      prevProps.room.round === nextProps.room.round &&
      prevProps.room.id === nextProps.room.id
    );
  }
);

CurrentScreen.displayName = "CurrentScreen";
