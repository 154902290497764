import { useEffect, useState } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";

const BarContainer = styled(motion.div)`
  height: 8px;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
`;

const ProgressBar = styled(motion.div)`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.highlight};
  border-radius: 4px;
  opacity: 0.7;
`;

export const LoadingBar = ({
  duration,
  onComplete,
  marginTop = 25,
  width = 75,
  delay = 0,
}: {
  duration: number;
  onComplete?: () => void;
  marginTop?: number;
  width?: number;
  delay?: number;
}) => {
  const controls = useAnimation();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    const sequence = async () => {
      await controls.start({
        width: "100%",
        transition: { duration, delay },
      });
      if (mounted && onComplete) onComplete();
    };

    sequence();

    return () => {
      setMounted(false);
    };
  }, [controls, duration, onComplete]);

  return (
    <BarContainer
      style={{ marginTop, width: width + "%" }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay, duration: 0.5 }}
    >
      <ProgressBar initial={{ width: "0%" }} animate={controls} />
    </BarContainer>
  );
};
