import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;
export const FinaleContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 600px;
  gap: 20px;
`;
export const ButtonContainer = styled(motion.div)`
  display: flex;
  width: 50%;
  gap: 20px;
`;
export const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 5px;
  border-radius: 10px;
  position: relative;
  background: linear-gradient(to right, #ffcc00, #ff66ff);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 4s ease infinite;
`;
export const ScoreContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px 30px;
  border-radius: 7.5px;
  background: linear-gradient(45deg, #000000bb, #000000bb);
  position: relative;
  z-index: 1;
`;
export const Title = styled(motion.h1)`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.text};
  opacity: 0.9;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
`;
export const Score = styled(motion.div)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
`;
export const ScoreName = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.text};
`;
export const ScoreIcon = styled(motion.div)`
  font-size: 1.5rem;
  margin-bottom: -6px;
`;
export const ScoreNumber = styled(motion.div)`
  font-size: 1.5rem;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.text};
  opacity: 0.8;
`;
