import styled, { keyframes } from "styled-components";
import { BotBodyPartProps } from "../../BotSVG";

const antennaMove = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const AnimatedAntenna = styled.g`
  animation: ${antennaMove} 3s linear infinite;
`;

const Antenna3 = ({
  colors: { base, outline, shine },
  outlineWeight,
}: BotBodyPartProps) => {
  return (
    <AnimatedAntenna id="antenna">
      <path
        d="M427.83,56.57c-15.4-6.49-107.61,97.72-107.61,97.72,0,0,48.67,119.14,67.07,115.68,28.78-5.41,95.43-86.7,95.43-86.7,0,0-41.48-121.05-54.89-126.7Z"
        style={{
          fill: "#79a7d9",
          strokeWidth: 0,
        }}
      />
      <polyline
        points="384.31 269.59 366.51 142.2 425.09 66.19 444.04 156.43"
        style={{
          fill: base,
          strokeWidth: 0,
        }}
      />
      <polygon
        id="Shine"
        points="386.02 116.88 406.32 90.54 428.79 83.83 435.67 116.57 386.02 116.88"
        style={{
          fill: shine,
          strokeWidth: 0,
        }}
      />
      <path
        d="M406.83,261.37c-22.8,21.14-26.2,21.58-40.69-6.03-15.18-28.94-29.21-56.38-44.14-89.2-4.18-9.18-2.22-14.27,4.01-21.1,27.42-30.05,59.85-54.58,89.46-82.21,9.94-9.27,14.75-7.12,20.92,7.19,13.63,31.57,37.68,91.95,40.57,98.66,7.97,18.46-4.73,30.8-19.05,45.13-19.7,19.71-30.44,28.43-51.08,47.56Z"
        style={{
          fill: "none",
          stroke: outline,
          strokeMiterlimit: 10,
          strokeWidth: outlineWeight,
        }}
      />
      <polyline
        points="324.14 157.83 367.51 139.46 442.91 156.15 473.99 181.25"
        style={{
          fill: "none",
          stroke: outline,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
    </AnimatedAntenna>
  );
};

export default Antenna3;
