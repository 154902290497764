import { BotBodyPartProps } from "../../BotSVG";

const Neck1 = ({
  colors: { base, outline, baseShadow },
  outlineWeight,
}: BotBodyPartProps) => (
  <g id="neck">
    <path
      d="M302.75,675.93c-31.52,7.66-56.7-5.92-81.48-19.79-7.63-4.27-4.34-12.16-.04-17.05,18.22-20.74,16.27-39.27,1.64-62.77-21.9-35.19-18.75-38.42,22.14-51.66,29.86-9.66,59.45-19.32,91.55-19.17,17.44.08,30.1,9.48,43.67,17.29,7.19,4.14,2.32,10.54-.96,15.36-10.31,15.18-20.18,30.71-31.48,45.13-10.62,13.56-11.63,24.23,2.79,36.06,8.45,6.93,14.38,16.91,22.63,26.97-22.6,13.57-46.74,19.44-70.46,29.63Z"
      style={{
        fill: base,
        strokeWidth: 0,
      }}
    />
    <g id="Pattern_Image_copy_4-2" data-name="Pattern\xA0Image copy 4">
      <path
        d="M343.69,661.38c-13.48,5.95-37.9,18.04-57.27,18.04-32.08,0-70.3-30.07-70.3-30.07-10.03-5.71,14.78-24.47,17.59-44.03,2.54-17.71-17.49-25.68-23.96-50.81-3.53-13.7,11.7-26.59,23.9-31.24,34.34-13.1,41.7-21.84,78.84-21.09,34.53.7,39.55,11.75,54.18,20.6,7.64,4.63-14.6,55.38-25.22,54.89-28.84-1.32-12.54,43.54-30.87,43.54-62.32,0,28.65,21.83,33.11,40.17Z"
        style={{
          fill: baseShadow,
          strokeWidth: 0,
        }}
      />
    </g>
    <g id="Neck_Image_copy_4" data-name="Neck\xA0Image copy 4">
      <path
        d="M375.21,641.3c3.09,2.35-31.01,32.38-69.53,34.37-58.22,3-83.21-17.05-86.47-19.73-22.21-18.26,47.97-3.2-9.42-101.43-11.05-18.92,27.43-31.85,39.36-35.34,83.78-24.52,140.52.25,134.24,11.89-27.3,50.62-58.69,71.73-8.18,110.24Z"
        style={{
          fill: "none",
          stroke: outline,
          strokeMiterlimit: 10,
          strokeWidth: outlineWeight,
        }}
      />
    </g>
  </g>
);

export default Neck1;
