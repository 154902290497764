import styled from "styled-components";
import { motion } from "framer-motion";

export const PromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 85%;
  max-width: 450px;
`;

export const PromptLabel = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  opacity: 0.5;
  margin: 0;
  padding-bottom: 0.75rem;
`;

export const PromptText = styled.h1`
  font-size: 1.5rem;
  margin: 0;
  padding-bottom: 2rem;
  text-align: center;

  .text {
    font-weight: normal;
    font-size: 1.3rem;
    opacity: 0.75;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`;

export const CheckmarkContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  height: 16rem;
  padding-bottom: 1rem;
`;

export const PromptInputContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PromptInput = styled(motion.textarea)<{
  $correct: boolean;
  $incorrect: boolean;
}>`
  box-sizing: border-box;
  font-size: 1.5rem;
  border-radius: 8px;
  box-sizing: border-box;
  border: none;
  background-color: #ffffff11;
  border: 2px solid rgba(255, 255, 255, 0.1);
  color: white;
  height: 9rem;
  font-family: ${(props) => props.theme.fonts.body};
  padding: 1rem;
  resize: none;
  width: 100%;
  outline: none;
  transition: border 0.5s;
  margin-bottom: 2rem;

  &:focus {
    border: 2px solid
      ${({ $correct, $incorrect, theme }) =>
        $correct
          ? theme.colors.success
          : $incorrect
          ? theme.colors.error
          : theme.colors.highlight};
    box-shadow: ${({ $correct, theme }) =>
      $correct ? theme.glow.button.success : "none"};
  }
`;

export const WordCounter = styled(motion.div)<{
  $correct: boolean;
  $incorrect: boolean;
}>`
  color: ${({ $correct, $incorrect, theme }) =>
    $correct
      ? theme.colors.success
      : $incorrect
      ? theme.colors.error
      : "white"};
  margin-top: -1rem;
  margin-bottom: 0.5rem;
  margin-left: -5px;
  font-size: 1rem;
  height: 1rem;
  opacity: ${(props) => (props.$correct || props.$incorrect ? 1 : 0.35)};
  text-align: right;
  width: 100%;
  transition: opacity 0.5s, color 0.5s;
`;
