import { motion } from "framer-motion";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import styled from "styled-components";

const StyledMuteButton = styled(motion.button)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  margin: 10px;
  border-radius: 50%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.gradients.backdrop};
  width: 48px;
  height: 48px;
  outline: none;
  border: none;
  color: #ffffff99;
  opacity: 0.5;

  &:hover {
    color: white;
    cursor: pointer;
    opacity: 1;
  }
`;

export const MuteButton = ({
  muted,
  toggleMute,
}: {
  muted: boolean;
  toggleMute: () => void;
}) => {
  return (
    <StyledMuteButton
      onClick={toggleMute}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      {muted ? <FaVolumeMute size={20} /> : <FaVolumeUp size={20} />}
    </StyledMuteButton>
  );
};
