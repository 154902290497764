import { useContext, useEffect, useState } from "react";
import { PlayerContext, RoomContext, SocketContext } from "../../App";
import { motion, useAnimation } from "framer-motion";
import styled from "styled-components";
import { BotSVG } from "../../assets/bots/BotSVG";
import { FaCheck, FaDice, FaTimes } from "react-icons/fa";
import { Bot } from "../../util/types";
import { DEFAULT_BOT_COLOR_THEME } from "../../assets/bots/themes";
import { getRandomBot } from "../../assets/bots/utils";
import { playBotSound } from "../../assets/bots/sounds";

const PlayerLobbyContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 600px;
  gap: 15px;
`;

const Button = styled(motion.button)`
  border: none;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.25rem;
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ShuffleButton = styled(Button)`
  background: ${({ theme }) => theme.gradients.backdrop};
  box-sizing: border-box;
  width: 75px;
  height: 75px;
`;

const ConfirmButton = styled(Button)`
  background: ${({ theme }) => theme.gradients.button.success};
  width: 50px;
  height: 50px;
  margin-left: 20px;
  margin-right: -70px;
`;

const CancelButton = styled(Button)`
  background: ${({ theme }) => theme.gradients.button.error};
  width: 50px;
  height: 50px;
  margin-right: 20px;
  margin-left: -70px;
`;

const SHUFFLE_TIME = 0.25;

export const PlayerLobby = () => {
  const socket = useContext(SocketContext);
  const player = useContext(PlayerContext);
  const room = useContext(RoomContext);

  const [botChanged, setBotChanged] = useState(false);
  const [generatingBot, setGeneratingBot] = useState(false);
  const [currentBot, setCurrentBot] = useState<Bot>({
    colors: DEFAULT_BOT_COLOR_THEME,
    parts: {
      antenna: 1,
      eyes: 1,
      head: 1,
      neck: 1,
      body: 1,
    },
    sound: 1,
  });

  if (!socket) throw new Error("Socket not found");
  if (!player) throw new Error("Player not found");

  useEffect(() => {
    setCurrentBot(player.bot);
    socket.on("playerBotChanged", ({ bot }): void => {
      setCurrentBot(bot);
      setGeneratingBot(false);
      setBotChanged(false);
    });
    return () => {
      socket.off("playerBotChanged");
    };
  }, []);

  useEffect(() => {
    setCurrentBot(player.bot);
  }, [player.bot]);

  const onConfirm = () => {
    socket.emit("changePlayerBot", {
      roomId: room?.id,
      bot: currentBot,
    });
  };

  const onCancel = () => {
    setCurrentBot(player.bot);
    setBotChanged(false);
  };

  const controls = useAnimation();
  const buttonControls = useAnimation();

  const onShuffleClick = () => {
    if (generatingBot) return;
    // Change bot
    setGeneratingBot(true);
    setBotChanged(true);
    startShuffleAnimation();
    const bot = getRandomBot(currentBot);
    // Play audio
    playBotSound(bot.sound, false, 0.35);
    setTimeout(() => {
      setCurrentBot(bot);
      // 50/50 chance of variant
    }, SHUFFLE_TIME * 500);
    setTimeout(() => {
      setGeneratingBot(false);
    }, SHUFFLE_TIME * 1000);
  };

  const startShuffleAnimation = () => {
    controls.start({
      filter: ["none", "blur(10px)", "none"], // Apply a blur filter to the image
      scale: [1, 0.8, 1], // Scale from 1 to 0.8 and back to 1
      opacity: [1, 0.5, 1], // Fade out and back in
      transition: { duration: SHUFFLE_TIME, ease: "easeIn" },
    });
    buttonControls.start({
      rotate: [0, 360], // Rotate from 0 to 360 degrees
      scale: [0.8, 1.2, 1], // Scale from 1 to 1.1 and back to 1
      transition: { duration: SHUFFLE_TIME, ease: "easeOut" }, // Loop the rotation infinitely
    });
  };

  return (
    <PlayerLobbyContainer
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, delay: 0.5 }}
    >
      {currentBot && (
        <motion.div
          style={{
            maxHeight: "50vh",
          }}
          animate={controls}
        >
          <BotSVG
            colors={currentBot.colors}
            parts={currentBot.parts}
            height={400}
          />
        </motion.div>
      )}
      <motion.div
        layout
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CancelButton
          key="cancel"
          disabled={generatingBot || !botChanged}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.8 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: botChanged ? 1 : 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
          onClick={onCancel}
        >
          <FaTimes size={20} opacity={0.75} />
        </CancelButton>
        <ShuffleButton
          key="shuffle"
          disabled={generatingBot}
          onClick={onShuffleClick}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.8 }}
          layout
          animate={buttonControls}
        >
          <FaDice size={40} opacity={0.75} />
        </ShuffleButton>
        <ConfirmButton
          key="confirm"
          disabled={generatingBot || !botChanged}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.8 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: botChanged ? 1 : 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
          onClick={onConfirm}
        >
          <FaCheck size={20} opacity={0.75} />
        </ConfirmButton>
      </motion.div>
    </PlayerLobbyContainer>
  );
};
