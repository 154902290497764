import { useContext, useEffect, useRef, useState } from "react";
import { SocketContext } from "../../App";
import { motion } from "framer-motion";
import styled, { ThemeContext } from "styled-components";

const SIZE = 50;
const STROKE_WIDTH = 5;
const RADIUS = SIZE / 2 - STROKE_WIDTH / 2;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

const TimerCircle = styled.svg`
  width: ${SIZE}px;
  height: ${SIZE}px;
  transition: opacity 1s;
`;

const TimerText = styled.text`
  font-size: 1.25em;
  text-anchor: middle;
  fill: #ffffffcc;
`;

// Create a component that automatically places the Timer component in the top right corner of the screen
const TimerContainer = styled.div<{ $fixed: boolean }>`
  position: ${({ $fixed }) => ($fixed ? "fixed" : "relative")};
  top: ${({ $fixed }) => ($fixed ? "20px" : "-18px")};
  right: ${({ $fixed }) => ($fixed ? "20px" : 0)};
  z-index: 10;
`;

export const Timer = ({ fixed = true }) => {
  const theme = useContext(ThemeContext);
  const socket = useContext(SocketContext);
  const [duration, setDuration] = useState<number>(0);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [strokeDashoffset, setStrokeDashoffset] = useState(CIRCUMFERENCE);

  const strokeColor =
    remainingTime <= 10
      ? theme?.colors.error || "red"
      : theme?.colors.highlight || "blue";

  if (!socket) {
    throw new Error("Socket not found");
  }

  useEffect(() => {
    socket.on("timerStart", ({ duration }: { duration: number }) => {
      setRemainingTime(duration);
      setDuration(duration);
    });

    socket.on("timerTick", ({ remainingTime }: { remainingTime: number }) => {
      setRemainingTime(remainingTime);
    });

    socket.on("timerEnd", () => {
      setRemainingTime(0);
    });

    return () => {
      socket.off("timerStart");
      socket.off("timerTick");
      socket.off("timerEnd");
    };
  }, []);

  useEffect(() => {
    if (remainingTime === 0) {
      setStrokeDashoffset(CIRCUMFERENCE);
    } else {
      const newStrokeDashoffset =
        CIRCUMFERENCE - (remainingTime / duration) * CIRCUMFERENCE;
      setStrokeDashoffset(newStrokeDashoffset);
    }
  }, [remainingTime, duration]);

  return (
    <TimerContainer $fixed={fixed}>
      <TimerCircle
        opacity={remainingTime === 0 ? 0 : 1}
        viewBox={`0 0 ${SIZE} ${SIZE}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          stroke={strokeColor}
          opacity={0.4}
          strokeOpacity={0.4}
          fill="transparent"
          strokeWidth={STROKE_WIDTH}
          r={RADIUS}
          cx={SIZE / 2}
          cy={SIZE / 2}
        />
        <motion.circle
          stroke={strokeColor}
          fill="transparent"
          strokeWidth={STROKE_WIDTH}
          r={RADIUS}
          cx={SIZE / 2}
          cy={SIZE / 2}
          strokeDasharray={CIRCUMFERENCE}
          strokeDashoffset={strokeDashoffset}
          style={{ transform: "rotate(-90deg)", transformOrigin: "50% 50%" }}
        />
        <TimerText x="50%" y="50%" dy=".3em" textAnchor="middle">
          {remainingTime === 0 ? "" : remainingTime}
        </TimerText>
      </TimerCircle>
    </TimerContainer>
  );
};
