import { useState, useEffect } from "react";
import { Socket, io } from "socket.io-client";
import { log } from "./log";
import { Room, Player, PlayerID, ROOM_STATE, Bot, Prompt } from "./types";
import { ALL_AUDIO_FILES } from "./useAudio";
import { SERVER_URL } from "../App";

export const useSocket = () => {
  const [socket] = useState<Socket>(
    io(SERVER_URL, {
      autoConnect: false,
    })
  );
  const [room, setRoom] = useState<Room>(null as unknown as Room);
  const [player, setPlayer] = useState<(Player & { id: string }) | null>(null);
  const [players, setPlayers] = useState<Record<PlayerID, Player>>({});
  const [isHost, setIsHost] = useState<boolean>(false);

  useEffect(() => {
    // Everyone gets this when they connect
    const onConnect = () => {
      log("Connected to server");
    };

    ALL_AUDIO_FILES.forEach((audioFile) => {
      const audio = new Audio(audioFile);
      audio.load();
    });

    // Only the host gets this when they create a room
    const onRoomCreated = (roomId: string) => {
      log(`Room created: ${roomId}`);
      setIsHost(() => true);
      setRoom({
        id: roomId,
        state: ROOM_STATE.lobby,
        round: 0,
        answers: {},
        ranks: {},
        votes: {},
        rankScores: {},
        voteScores: {},
      });
    };

    // A host gets this when a player joins their room
    const onPlayerJoined = ({
      name,
      score,
      playerId,
      bot,
      isAI,
    }: {
      name: string;
      score: number;
      playerId: PlayerID;
      bot: Bot;
      isAI: boolean;
    }) => {
      log(`${name} joined the room`);
      setPlayers((prevPlayers) => {
        const newPlayers = { ...prevPlayers };
        // Check if an existing player reconnected by name
        const [existingPlayerId, existingPlayer] =
          Object.entries(newPlayers).find(
            ([, player]) => player.name === name
          ) || [];
        // If the player already exists, update their score and connection status
        if (existingPlayerId && existingPlayer) {
          log(
            `${name} reconnected, changed ID from ${existingPlayerId} to ${playerId}`
          );
          existingPlayer.score = score;
          existingPlayer.isConnected = true;
          delete newPlayers[existingPlayerId];
          newPlayers[playerId] = existingPlayer;
          return newPlayers;
        }

        newPlayers[playerId] = { name, score, isConnected: true, bot, isAI };
        return newPlayers;
      });
    };

    // Only the host gets this when a player disconnects
    const onPlayerLeft = ({
      playerId,
      state,
    }: {
      playerId: PlayerID;
      state: ROOM_STATE;
    }) => {
      setPlayers((prevPlayers) => {
        const newPlayers = { ...prevPlayers };
        if (state === ROOM_STATE.lobby) {
          // If the game hasn't started, remove the player from the room
          delete newPlayers[playerId];
        } else {
          // If the game has started, mark the player as disconnected
          newPlayers[playerId].isConnected = false;
        }
        return newPlayers;
      });
    };

    // Only players get this when the host disconnects
    const onHostLeft = () => {
      log("Host left the room");
      setRoom(null as unknown as Room);
    };

    // A player gets this when they join a room
    const onRoomJoined = ({
      answers,
      name,
      playerId,
      bot,
      prompt,
      score,
      state,
      roomId,
      round,
    }: Room & Player & { roomId: string; playerId: string }) => {
      log(`${name} joined the room ${roomId}`);
      setPlayer({ name, score, isConnected: true, id: playerId, bot });
      setRoom({
        answers,
        id: roomId,
        prompt,
        ranks: {},
        votes: {},
        round,
        state,
        rankScores: {},
        voteScores: {},
      });
    };

    // Only the host gets this when a player changes their bot
    const onPlayerBotChanged = ({
      playerId,
      bot,
    }: {
      playerId: PlayerID;
      bot: Bot;
    }) => {
      setPlayer((prevPlayer) => {
        if (prevPlayer) {
          return { ...prevPlayer, bot };
        }
        return prevPlayer;
      });
      setPlayers((prevPlayers) => {
        const newPlayers = { ...prevPlayers };
        if (newPlayers[playerId]) {
          newPlayers[playerId].bot = bot;
        }
        return newPlayers;
      });
    };

    // Everyone gets this when the host clicks "Start Game"
    // This should reset player answers and set the new prompt
    const onStartRound = ({
      prompt,
      round,
    }: {
      prompt: Prompt;
      round: number;
    }) => {
      if (!prompt) {
        log("Prompt not found", "error");
        socket.emit("startRound", { roomId: room.id });
        return;
      }
      log(`Starting a ${prompt.type} round, prompt: "${prompt.prompt}"`);
      setRoom((prevRoom) => ({
        ...prevRoom,
        state: ROOM_STATE.answering_prompt,
        round,
        prompt,
        answers: {},
        ranks: {},
      }));
    };

    // All players get this once the host has received all answers (or time is up)
    const onAllAnswersReceived = ({
      answers,
    }: {
      answers: Record<string, string>;
    }) => {
      log(`All answers received with ${Object.keys(answers).length} answers`);
      setRoom((prevRoom) => {
        return { ...prevRoom, state: ROOM_STATE.player_voting, answers };
      });
    };

    const onNoAnswersReceived = () => {
      log("No answers received, skipping round...");
      setRoom((prevRoom) => {
        return { ...prevRoom, state: ROOM_STATE.no_answers, answers: {} };
      });
    };

    // Only players get this when the host starts player voting (after animation)
    const onStartPlayerVoting = ({
      answers,
    }: {
      answers: Record<string, string>;
    }) => {
      log(`Starting player voting with ${Object.keys(answers).length} answers`);
      setRoom((prevRoom) => {
        return { ...prevRoom, state: ROOM_STATE.player_voting, answers };
      });
    };

    // All players get this when the player voting is complete/time is up
    const onStartAIRanking = ({
      ranks,
      votes,
      rankScores,
      voteScores,
    }: {
      ranks: Record<PlayerID, number>;
      votes: Record<PlayerID, PlayerID>;
      rankScores: Record<PlayerID, number>;
      voteScores: Record<PlayerID, number>;
    }) => {
      log(`Starting AI ranking with ${Object.keys(ranks).length} ranks`);
      setRoom((prevRoom) => {
        return {
          ...prevRoom,
          state: ROOM_STATE.ai_ranking,
          ranks,
          votes,
          rankScores,
          voteScores,
        };
      });
    };

    const updateTotalScore = (scores: Record<PlayerID, number>) => {
      setPlayers((prevPlayers) => {
        if (Object.keys(prevPlayers).length === 0) return prevPlayers;
        const newPlayers = { ...prevPlayers };
        Object.entries(scores).forEach(([playerId, score]) => {
          if (!newPlayers[playerId]) return;
          newPlayers[playerId].score = score;
        });
        return newPlayers;
      });
    };

    const onShowScores = ({ scores }: { scores: Record<PlayerID, number> }) => {
      log(`Showing scores...`);
      updateTotalScore(scores);
      setRoom((prevRoom) => ({ ...prevRoom, state: ROOM_STATE.show_scores }));
    };

    const onGameEnded = ({ scores }: { scores: Record<PlayerID, number> }) => {
      log(`Game ended!`);
      updateTotalScore(scores);
      setRoom((prevRoom) => ({ ...prevRoom, state: ROOM_STATE.end }));
    };

    socket.on("connect", onConnect);
    socket.on("roomCreated", onRoomCreated);
    socket.on("playerJoined", onPlayerJoined);
    socket.on("playerLeft", onPlayerLeft);
    socket.on("hostLeft", onHostLeft);
    socket.on("roomJoined", onRoomJoined);
    socket.on("playerBotChanged", onPlayerBotChanged);
    socket.on("startRound", onStartRound);
    socket.on("allAnswersReceived", onAllAnswersReceived);
    socket.on("noAnswersReceived", onNoAnswersReceived);
    socket.on("startPlayerVoting", onStartPlayerVoting);
    socket.on("startAIRanking", onStartAIRanking);
    socket.on("showScores", onShowScores);
    socket.on("gameEnded", onGameEnded);
    socket.on("disconnect", () => {
      console.warn("Disconnected from server");
      setRoom(null as unknown as Room);
      setPlayer(null);
      setPlayers({});
      setIsHost(false);
    });

    return () => {
      socket.off("connect", onConnect);
      socket.off("roomCreated", onRoomCreated);
      socket.off("playerJoined", onPlayerJoined);
      socket.off("playerLeft", onPlayerLeft);
      socket.off("hostLeft", onHostLeft);
      socket.off("roomJoined", onRoomJoined);
      socket.off("playerBotChanged", onPlayerBotChanged);
      socket.off("startRound", onStartRound);
      socket.off("allAnswersReceived", onAllAnswersReceived);
      socket.off("noAnswersReceived", onNoAnswersReceived);
      socket.off("startPlayerVoting", onStartPlayerVoting);
      socket.off("startAIRanking", onStartAIRanking);
      socket.off("showScores", onShowScores);
      socket.off("gameEnded", onGameEnded);
      socket.disconnect();
    };
  }, []);

  return { socket, room, player, players, isHost };
};
