import { useContext } from "react";
import { LoadingBar } from "../components/LoadingBar";
import { Prompt } from "./components/AnsweringPrompt";
import { RoomContext, SocketContext } from "../../App";
import { Button } from "../components/Button";
import styled from "styled-components";
import { motion } from "framer-motion";

const NoAnswerContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
  align-items: center;
  width: 80%;
  max-width: 450px;
  text-align: center;
`;

export const NoAnswers = () => {
  const socket = useContext(SocketContext);
  const room = useContext(RoomContext);

  if (!socket || !room) throw new Error("Socket or room not found");

  const endGame = () => {
    socket.disconnect();
    window.location.reload();
  };

  const startNextRound = () => {
    if (socket && socket.connected)
      socket.emit("skipRound", { roomId: room.id });
  };

  return (
    <NoAnswerContainer
      transition={{
        duration: 1,
        ease: "easeInOut",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Prompt>No one answered! Are you still playing?</Prompt>
      <Button
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1 }}
        color="highlight"
        onClick={startNextRound}
      >
        Yes! Start next round...
      </Button>
      <Button
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 2 }}
        color="error"
        onClick={endGame}
      >
        No! End this game.
      </Button>
      <LoadingBar duration={20} onComplete={endGame} />
    </NoAnswerContainer>
  );
};
