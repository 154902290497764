export const theme = {
  colors: {
    background: "#282F36",
    backgroundSecondary: "#323941",
    error: "#B54C51",
    highlight: "#6E7DAB",
    success: "#7CA27F",
    text: "#F0EFF4",
  },
  gradients: {
    backdrop: "linear-gradient(135deg, #FFFFFF05 0%, #FFFFFF11 100%)",
    button: {
      highlight: "linear-gradient(135deg, #6E7DAB44 0%, #6E7DAB99 100%)",
      success: "linear-gradient(135deg, #7CA27F44 0%, #7CA27F99 100%)",
      error: "linear-gradient(135deg, #B54C5144 0%, #B54C5199 100%)",
    },
  },
  border: {
    button: {
      highlight: "2px ridge #6E7DAB",
      success: "2px ridge #7CA27F",
      error: "2px ridge #B54C51",
    },
  },
  glow: {
    button: {
      highlight: "0 0 12px 0 #6E7DAB55",
      success: "0 0 12px 0 #7CA27F55",
      error: "0 0 12px 0 #B54C5155",
    },
  },
  fonts: {
    body: "'Raleway', 'sans-serif'",
  },
};
