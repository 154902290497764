import { BotBodyPartProps } from "../../BotSVG";

const Head3 = ({
  colors: { base, accent, accentShadow, outline, shine },
  outlineWeight,
}: BotBodyPartProps) => (
  <g id="head">
    <g id="Top">
      <path
        d="M106.45,539.28c-14.76-16.69-50.79-196.83-17.22-214.31,87.21-45.42,152.07-47.88,223.25-50.66,41.53-1.62,159.05,10.76,189.54,52.77,15.44,21.28-14.5,193.18-22.15,208.48-8.85,17.7-72.52,25-177.33,29.07-82.58,3.19-177.59-4.43-196.09-25.35Z"
        style={{
          fill: base,
          strokeWidth: 0,
        }}
      />
      <path
        d="M170.42,526.71c-11.69-22.49-40.3-148.27-22.92-186.73,10.71-23.71,272.05-32.71,289.86,4.8,9.19,19.36-4.59,162.54-10.6,172.63-8.52,14.28-247.42,26.47-256.34,9.3Z"
        style={{
          fill: "#79a7d9",
          strokeWidth: 0,
        }}
      />
      <path
        d="M505.47,341.49c0-14.24-5.2-17.42-13.44-22.46-54.11-33.1-93.19-40.38-126.48-42.62-37.43-2.52-98.45-3.65-130.94.35-38,4.67-105.61,22.95-145.38,48.2-12.55,7.96-15.33,16.44-16.99,35.93-2.08,24.43,10.48,108.22,17.28,144.13,1.39,7.34,5.52,23.54,12.49,30.52,48.92,48.92,366.57,27.93,377.2,1.6,2.32-5.74,9.11-20.64,10.54-32.96,2.06-17.71,15.72-117.27,15.72-162.69Z"
        style={{
          fill: "none",
          stroke: outline,
          strokeMiterlimit: 10,
          strokeWidth: outlineWeight,
        }}
      />
      <path
        d="M146.89,442.69s-17.3-93.18.61-102.71c17.06-9.08,88.87-16.85,88.87-16.85"
        style={{
          fill: "none",
          stroke: outline,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
      <path
        d="M343.29,531.22s69.76-2.26,77.94-17.57c7.79-14.59,16.29-76.17,16.29-76.17"
        style={{
          fill: "none",
          stroke: outline,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
      <polygon
        points="168.59 364.45 248.8 350.7 247.46 364.03 170.88 377.91 168.59 364.45"
        style={{
          fill: "none",
          stroke: shine,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
      <polygon
        points="278.23 350.7 306.94 344.77 304 360.98 278.37 362.3 278.23 350.7"
        style={{
          fill: "none",
          stroke: shine,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
    </g>
    <g id="Btm">
      <path
        d="M49.6,552.04c-2.2,14.98,29.98,52.63,29.98,52.63l417.06.67s27.58-39.88,27.32-55.3c-.13-7.75-14.66-27.32-14.66-27.32l-431.05,2s-27.21,17.53-28.65,27.32Z"
        style={{
          fill: base,
          stroke: outline,
          strokeMiterlimit: 10,
          strokeWidth: outlineWeight,
        }}
      />
      <path
        d="M209.5,590.53c-.74,6.41,10.03,22.53,10.03,22.53l139.57.29s10.53-14.56,11.35-22.93c.31-3.16-3.48-11.7-7.11-12.43-37.59-7.53-122.18-5.83-144.25.86-3.99,1.19-9.11,7.49-9.59,11.68Z"
        style={{
          fill: base,
          stroke: outline,
          strokeMiterlimit: 10,
          strokeWidth: outlineWeight,
        }}
      />
      <line
        x1={416.4}
        y1={569.39}
        x2={476.38}
        y2={570.63}
        style={{
          fill: "none",
          stroke: "#79a7d9",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
      <line
        x1={111.82}
        y1={570.01}
        x2={171.79}
        y2={568.01}
        style={{
          fill: "none",
          stroke: "#79a7d9",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: outlineWeight,
        }}
      />
      <polygon
        points="61.38 479.21 74.33 522.71 169.36 537.21 160.81 493.45 132.07 490.86 126.63 470.4 80.02 468.59 80.02 484.38 61.38 479.21"
        style={{
          fill: "#79a7d9",
          stroke: outline,
          strokeMiterlimit: 10,
        }}
      />
      <polyline
        points="85.24 466.49 98.24 527.94 130.79 537.21 121.9 470.22"
        style={{
          fill: base,
          strokeWidth: 0,
        }}
      />
      <polygon
        points="70.63 520.74 52.88 475.31 79.64 478.32 76.51 465.71 130.47 470.58 132.49 488.05 164.37 490.79 170.77 538.5 70.63 520.74"
        style={{
          fill: "none",
          stroke: outline,
          strokeMiterlimit: 10,
          strokeWidth: outlineWeight,
        }}
      />
      <polygon
        points="449.56 495.4 439.76 508.59 416.4 508.96 419.03 541.37 510.98 540.24 520.03 508.96 497.04 509.34 497.79 496.15 449.56 495.4"
        style={{
          fill: accentShadow,
          strokeWidth: 0,
        }}
      />
      <polyline
        points="455.91 496.27 456.06 541.37 483.46 538.5 490.63 501"
        style={{
          fill: accent,
          strokeWidth: 0,
        }}
      />
      <polygon
        points="510.97 537.87 522.67 508.55 497.42 507.53 498.95 499.18 447.91 496.27 448.01 508.11 417.84 506.29 417.26 538.5 510.97 537.87"
        style={{
          fill: "none",
          stroke: outline,
          strokeMiterlimit: 10,
          strokeWidth: outlineWeight,
        }}
      />
    </g>
  </g>
);

export default Head3;
