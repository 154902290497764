import { motion } from "framer-motion";
import { FaRobot } from "react-icons/fa";
import CountUp from "react-countup";

import {
  RankedAnswerContainer,
  RankedAnswerAuthor,
  RankedAnswerNumber,
  RoundScore,
  RankedAnswerText,
  RankedScore,
  VoteScore,
  Badges,
  VoteBadge,
  RankedAnswerBot,
} from "./AIRanking";

import { AI_ID } from "../../../App";
import { Player, Room } from "../../../util/types";
import { BotSVG } from "../../../assets/bots/BotSVG";
import { useEffect } from "react";
import { playBotSound } from "../../../assets/bots/sounds";

interface RankedAnswerComponentProps {
  playerId: string;
  rank: number;
  index: number;
  isAI: boolean;
  revealAI: boolean;
  revealPlayers: boolean;
  revealScores: boolean;
  revealVotes: boolean;
  AIRank: number;
  player: Player;
  room: Room;
}

const BeforeRevealAI = ({ rank }: { rank: number }) => (
  <RankedAnswerNumber>
    <span className="rank">{rank}</span>
  </RankedAnswerNumber>
);

const RevealAI = () => (
  <motion.div
    initial={{ scale: 0 }}
    animate={{ rotate: 360, scale: 1 }}
    transition={{
      duration: 1,
      type: "spring",
      stiffness: 260,
      damping: 20,
    }}
  >
    <FaRobot size={25} />
  </motion.div>
);

const RevealPlayer = ({
  player,
  rank,
  AIRank,
  index,
  showRankStatus,
}: {
  player: Player;
  rank: number;
  AIRank: number;
  index: number;
  showRankStatus: boolean;
}) => {
  const color = player.bot.colors.base;

  return (
    <RankedAnswerAuthor
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      $playerColor={color}
      $variant={
        showRankStatus ? (rank < AIRank ? "success" : "error") : undefined
      }
      transition={{
        delay: 1.5 * index,
      }}
    >
      {player.name}
    </RankedAnswerAuthor>
  );
};

const RevealScore = ({
  rank,
  AIRank,
  index,
  initialScore,
  scoreBonus,
}: {
  rank: number;
  AIRank: number;
  index: number;
  initialScore: number;
  scoreBonus: number;
}) => (
  <div>
    <RoundScore
      initial={{ x: -20, scale: 0 }}
      animate={{ x: 0, scale: 1 }}
      transition={{
        delay: 0.75 * index,
      }}
    >
      <RankedScore $variant={rank < AIRank ? "success" : "error"}>
        +
        <CountUp end={initialScore + scoreBonus} delay={0.75 * index} />
      </RankedScore>
    </RoundScore>
  </div>
);

export const RankedAnswer = ({
  playerId,
  rank,
  index,
  isAI,
  revealAI,
  revealPlayers,
  revealScores,
  revealVotes,
  AIRank,
  player,
  room,
}: RankedAnswerComponentProps) => {
  let rankScore = room.rankScores[playerId] || 0;
  const voteScore = room.voteScores[playerId] || 0;
  // How many players voted for this player's answer, thinking it was the AI's
  const playersTricked = Object.values(room.votes).filter(
    (vote) => vote === playerId
  ).length;
  // Whether the player voted for the AI
  const caughtAI = Object.entries(room.votes).find(
    ([voterId, votedForId]) => votedForId === AI_ID && voterId === playerId
  )?.length;
  // Whether the AI Judge was tricked by this player
  const trickedAI = AIRank > rank;

  const revealPlayersTrickedBadge = revealVotes && playersTricked > 0 && !isAI;
  const revealCaughtAIBadge = revealVotes && caughtAI && !isAI;
  const revealTrickedAIBadge = revealVotes && trickedAI && isAI;

  // If the player voted for the AI, they get a +100 bonus
  const caughtAIBonus = caughtAI ? 100 : 0;
  // If the AI Judge was tricked, player gets a +100 bonus
  const trickedAIBonus = trickedAI ? 100 : 0;
  // The number of players that believed this player's answer was the AI's answer
  const playersTrickedBonus = voteScore - caughtAIBonus;
  rankScore -= trickedAIBonus;
  const i = trickedAI ? index : index - 1;

  useEffect(() => {
    console.log(revealPlayers);
    if (revealPlayers && !isAI) {
      playBotSound(player.bot.sound, !trickedAI, 0.35, 0.75 * index * 1000);
    }
  }, [revealPlayers]);

  const playerAnswerLength = room.answers[playerId]
    ? room.answers[playerId].length
    : 0;

  return (
    <RankedAnswerContainer
      key={playerId}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 2 + 1.5 * index }}
      $isAI={isAI && revealAI}
      $variant={
        !isAI && revealScores
          ? rank < AIRank
            ? "success"
            : "error"
          : undefined
      }
      $hideText={revealPlayers && !isAI}
    >
      {isAI && revealAI ? <RevealAI /> : <BeforeRevealAI rank={rank} />}
      <RankedAnswerText className={playerAnswerLength === 0 ? "no-answer" : ""}>
        {playerAnswerLength > 0 ? room.answers[playerId] : "No answer"}
      </RankedAnswerText>
      {!isAI && revealPlayers && (
        <RankedAnswerBot
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            delay: 1.5 * i + 0.1 * i,
          }}
        >
          <BotSVG
            parts={player.bot.parts}
            colors={player.bot.colors}
            height={80}
          />
        </RankedAnswerBot>
      )}
      {!isAI && revealScores && (
        <RevealScore
          rank={rank}
          AIRank={AIRank}
          index={i}
          initialScore={rankScore}
          scoreBonus={
            revealVotes
              ? playersTrickedBonus + caughtAIBonus + trickedAIBonus
              : 0
          }
        />
      )}
      <Badges className="badges">
        {revealPlayersTrickedBadge && (
          <VoteBadge
            key="revealed-votes"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            <VoteScore
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
            >
              +{playersTrickedBonus}
            </VoteScore>
            Tricked {playersTricked} player{playersTricked > 1 ? "s" : ""}
          </VoteBadge>
        )}
        {revealCaughtAIBadge && (
          <VoteBadge
            key="caught-ai"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            <VoteScore
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
            >
              +{caughtAIBonus}
            </VoteScore>
            Caught the AI Player
          </VoteBadge>
        )}
        {revealTrickedAIBadge && (
          <VoteBadge
            key="tricked-ai"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            <VoteScore
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
            >
              +{trickedAIBonus}
            </VoteScore>
            Tricked AI Judge
          </VoteBadge>
        )}
        {!isAI && revealPlayers && (
          <RevealPlayer
            player={player}
            rank={rank}
            AIRank={AIRank}
            index={i}
            showRankStatus={revealScores}
          />
        )}
      </Badges>
    </RankedAnswerContainer>
  );
};
