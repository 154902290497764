import { BotBodyPartProps } from "../../BotSVG";

const Eyes2 = ({ colors: { outline } }: BotBodyPartProps) => (
  <g id="eyes">
    <path
      d="M221.82,470.63c-14.96-15.7-5.84-29.32,4.2-39.32,10.37-10.32,24.16-4.9,34.57,2.81,9.72,7.21,10.79,21.89,3.18,31.54-10.82,13.73-23.27,19.75-41.95,4.97Z"
      style={{
        fill: outline,
        strokeWidth: 0,
      }}
    />
    <path
      d="M325.82,467.63c-14.96-15.7-5.84-29.32,4.2-39.32,10.37-10.32,24.16-4.9,34.57,2.81,9.72,7.21,10.79,21.89,3.18,31.54-10.82,13.73-23.27,19.75-41.95,4.97Z"
      style={{
        fill: outline,
        strokeWidth: 0,
      }}
    />
  </g>
);

export default Eyes2;
