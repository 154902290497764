import { useContext, useState } from "react";
import styled from "styled-components";
import {
  RoomContext,
  PlayersContext,
  SocketContext,
  PlayerContext,
} from "../../App";
import { PromptLabel } from "./components/AnsweringPrompt";
import { motion } from "framer-motion";
import { Timer } from "../components/Timer";

const PlayerAnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
  align-items: flex-start;
  width: 80%;
  text-align: center;
  align-items: center;
`;

const PlayerAnswer = styled(motion.div)<{
  $playerSelectedAnAnswer: boolean;
  $thisAnswerSelected: boolean;
}>`
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  opacity: ${(props) =>
    props.$playerSelectedAnAnswer ? (!props.$thisAnswerSelected ? 0.5 : 1) : 1};
  align-items: center;
  padding: 12px 10px 10px 10px;
  border: 2px solid
    ${(props) =>
      props.$thisAnswerSelected
        ? props.theme.colors.highlight
        : "rgba(255, 255, 255, 0.1)"};
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
`;

const PlayerAnswerNumber = styled.p`
  text-align: right;
  width: 30px;
  font-size: 2rem;
  font-weight: bold;
  opacity: 0.3;
  margin: 0;
`;

const PlayerAnswerText = styled.div`
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 10px;
  font-size: 1.25rem;
  margin: 0;
  width: 100%;
`;

export const PlayerPlayerVoting = () => {
  const socket = useContext(SocketContext);
  const room = useContext(RoomContext);
  const player = useContext(PlayerContext);
  const players = useContext(PlayersContext);
  const { answers } = room;

  if (!socket || !room || !players) {
    return <h1>Waiting for connection...</h1>;
  }

  const [isAnswerSelected, setIsAnswerSelected] = useState<boolean>(false);
  const [selectedAnswerId, setSelectedAnswerId] = useState<string | null>(null);

  const handleAnswerSelect = (playerId: string) => {
    if (playerId === selectedAnswerId) {
      socket.emit("playerRemoveVote", { playerId, roomId: room.id });
      setIsAnswerSelected(false);
      setSelectedAnswerId(null);
    } else {
      setIsAnswerSelected(true);
      setSelectedAnswerId(playerId);
      socket.emit("playerAddVote", { playerId, roomId: room.id });
    }
  };

  return (
    <PlayerAnswerContainer>
      <Timer />
      <PromptLabel>Click the answer you suspect is by AI!</PromptLabel>
      {Object.entries(answers).map(
        ([playerId, answer], index) =>
          answer.length !== 0 &&
          playerId !== player?.id && (
            <PlayerAnswer
              key={playerId}
              onClick={() => {
                playerId !== player?.id && handleAnswerSelect(playerId);
              }}
              whileTap={{ scale: 0.95 }}
              $playerSelectedAnAnswer={isAnswerSelected}
              $thisAnswerSelected={selectedAnswerId === playerId}
            >
              <PlayerAnswerNumber>{index + 1}</PlayerAnswerNumber>
              <PlayerAnswerText>{answer}</PlayerAnswerText>
            </PlayerAnswer>
          )
      )}
    </PlayerAnswerContainer>
  );
};
