export type PlayerID = string;

export type Player = {
  name: string;
  score: number;
  isAI?: boolean;
  isConnected: boolean;
  bot: Bot;
};

export enum ROOM_STATE {
  lobby,
  answering_prompt,
  showing_answers,
  no_answers,
  player_voting,
  ai_ranking,
  show_scores,
  end,
}

export interface Bot {
  parts: BotParts;
  colors: BotColors;
  sound: number;
}

export interface BotParts {
  antenna: number;
  eyes: number;
  head: number;
  neck: number;
  body: number;
}

export interface BotColors {
  base: string;
  accent: string;
  outline: string;
  shine: string;
  baseShadow: string;
  accentShadow: string;
}

export type Room = {
  id: string;
  state: ROOM_STATE;
  prompt?: Prompt;
  answers: Record<PlayerID, string>;
  ranks: Record<PlayerID, number>;
  votes: Record<PlayerID, PlayerID>;
  rankScores: Record<PlayerID, number>;
  voteScores: Record<PlayerID, number>;
  round: number;
};

export enum PROMPT_TYPES {
  TEXT = "text",
  IMAGE = "image",
  REWRITE = "rewrite",
}

interface BasePrompt {
  prompt: string;
  wordLimit: number;
}

export interface TextPrompt extends BasePrompt {
  type: PROMPT_TYPES.TEXT;
}

export interface ImagePrompt extends BasePrompt {
  type: PROMPT_TYPES.IMAGE;
  url: string;
}

export interface RewritePrompt extends BasePrompt {
  type: PROMPT_TYPES.REWRITE;
  text: string;
}

export type Prompt = TextPrompt | ImagePrompt | RewritePrompt;
