import standard1 from "./1/standard.mp3";
import standard2 from "./2/standard.mp3";
import standard3 from "./3/standard.mp3";
import standard4 from "./4/standard.mp3";
import standard5 from "./5/standard.mp3";

import variant1 from "./1/variant.mp3";
import variant2 from "./2/variant.mp3";
import variant3 from "./3/variant.mp3";
import variant4 from "./4/variant.mp3";
import variant5 from "./5/variant.mp3";

const BEEP_VOLUME = 0.5;

const BOT_BEEPS: Record<number, Record<string, HTMLAudioElement>> = {
  1: {
    standard: new Audio(standard1),
    variant: new Audio(variant1),
  },
  2: {
    standard: new Audio(standard2),
    variant: new Audio(variant2),
  },
  3: {
    standard: new Audio(standard3),
    variant: new Audio(variant3),
  },
  4: {
    standard: new Audio(standard4),
    variant: new Audio(variant4),
  },
  5: {
    standard: new Audio(standard5),
    variant: new Audio(variant5),
  },
};

export const getBotSound = (id: number, variant: boolean = false) => {
  return BOT_BEEPS[id][variant ? "variant" : "standard"];
};

export const preloadBotSounds = () => {
  Object.values(BOT_BEEPS).forEach((sound) => {
    sound.standard.load();
    sound.variant.load();
  });
};

export const playBotSound = (
  id: number,
  variant: boolean = false,
  volume = BEEP_VOLUME,
  delay = 0
) => {
  setTimeout(() => {
    getBotSound(id, variant).volume = volume;
    getBotSound(id, variant).play();
  }, delay);
};

export const stopBotSound = (id: number, variant: boolean = false) => {
  getBotSound(id, variant).pause();
};

export const getRandomBotSound = () => {
  const soundIndex = Math.floor(Math.random() * 5) + 1;
  return soundIndex;
};
